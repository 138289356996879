import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {AlertController} from '@ionic/angular';
import {Globales} from '../../../Classes/globales';

@Component({
    selector: 'smd-reserva-item',
    templateUrl: './reserva-list-item.component.html',
    styleUrls: ['./reserva-list-item.component.scss'],
})
export class ReservaListItemComponent implements OnInit {

    @Input() item: any = null;

    @Output() pressMesa = new EventEmitter();
    @Output() pressPrecio = new EventEmitter();
    @Output() pressPax = new EventEmitter();
    @Output() pressArchivo = new EventEmitter();
    @Output() pressChat = new EventEmitter();

    constructor(
        private alertController: AlertController,
        private gl: Globales
    ) {
    }

    ngOnInit() {
    }


    onClickMesaEvent() {
        this.pressMesa.emit();
    }

    onClickPrecioEvent() {
        this.pressPrecio.emit();
    }

    onClickPaxEvent() {
        this.pressPax.emit();
    }

    onClickArchivoEvent() {
        this.pressArchivo.emit();
    }

    onClickChatEvent() {
        this.pressChat.emit();
    }

    /**
     * Abre mas información sobre la resevra
     *
     */
    async showAceptInfo() {

        if ((this.item.aceptado === 1 || this.item.aceptado === 2) && this.item.aceptado_por) {

            let msg = '<p>' + (this.item.aceptado === 1 ? 'Aceptada' : 'Cancelada') + ' por <strong>' + this.item.aceptado_por + '</strong></p>';

            if (this.item.atendido_por) {

                msg += '<p>Cheking por <strong>' + this.item.atendido_por + '</strong></p>';
            }

            const alert = await this.alertController.create({
                header: 'Información estado',
                message: msg,
            });

            await alert.present();

        } else {

            this.gl.showToast('No hay información diponible sobre el estado.', 2000);
        }
    }
}
