import {Component, OnInit} from '@angular/core';
import {LoadingController, ModalController, NavController, IonItemSliding, NavParams, Platform} from '@ionic/angular';

import {ConectorApi, ReservaForm, ApiResponse} from '../../../Classes/conector-api';
import {Globales} from '../../../Classes/globales';
import {UserService} from '../../../Services/user-service.service';
import {LangsService} from '../../../Services/langs.service';
import {ModalEditarRelacionesPublicasPage} from '../modal-editar-relaciones-publicas/modal-editar-relaciones-publicas.page';

@Component({
    selector: 'app-modal-solicitar-reservado',
    templateUrl: './modal-solicitar-reservado.page.html',
    styleUrls: ['./modal-solicitar-reservado.page.scss'],
})
export class ModalSolicitarReservadoPage implements OnInit {

    evento: any;
    mesa: any;
    forzarReserva: any;
    usuario: any;
    user: any;
    langs: any;
    needAuthorize: any = false;
    form: ReservaForm;

    constructor(
        private navParams: NavParams,
        private navCtrl: NavController,
        private loadCtrl: LoadingController,
        private modalCtrl: ModalController,
        public platform: Platform,
        private conectorApi: ConectorApi,
        private gl: Globales,
        private userService: UserService,
        private langsService: LangsService,
    ) {

        this.user = this.userService.get();
        this.langs = this.langsService.get();
        this.evento = this.navParams.get('item');
        this.mesa = this.navParams.get('mesa');
        this.forzarReserva = this.navParams.get('forzar');
        this.usuario = this.user;

        this.form = {
            idLang: '1',
            idEvento: this.evento.id,
            idReservado: this.mesa.id,
            idUsuario: this.user.id,
            idTipoPago: this.evento.local_full.tipos_pago[0].id,
            nombre: '',
            email: '',
            pax: null,
            precio: this.mesa.precio,
            autoriza: null,
            forzar: this.forzarReserva ? 1 : 0,
            comentarios: ''
        };
    }

    ngOnInit() {
    }


    /**
     * Envia una solicitud
     *
     */
    async setSolicitud() {

        const loading = await this.loadCtrl.create({
            translucent: false,
        });

        loading.present();

        if (this.form.idEvento && this.form.idReservado && this.form.idTipoPago
            && this.form.nombre && this.form.pax && (!this.needAuthorize || this.form.autoriza)) {


            this.conectorApi.setSolicitudReservado(this.user, this.form).then(
                (result: ApiResponse) => {

                    if (result.status === 1) {
                        this.dismiss(result.data);
                        this.gl.showToast(result.mensaje, result.duracion, 'success');
                    } else {
                        this.gl.showToast(result.mensaje, result.duracion);
                    }
                    loading.dismiss();
                },
                () => {

                    loading.dismiss();
                });

        } else {

            loading.dismiss();
            this.gl.showToast('Introduce todos los datos obligatorios', 2000);
        }
    }


    /**
     * Abre modal selector para cambiar relacciones publicas
     *
     */
    async selestRelacionesPublicas() {

        const modal = await this.modalCtrl.create({
            component: ModalEditarRelacionesPublicasPage,
            componentProps: {item: this.usuario, return: true}
        });

        if (this.gl.checkPermissions('reservas', 'editar')) {

            modal.present();
        }

        modal.onDidDismiss().then((respuesta) => {

            if (respuesta && respuesta.data) {
                this.usuario = respuesta.data;
                this.form.idUsuario = respuesta.data.id;
            }
        });
    }


    /**
     * Segun el tipo de pago determina si "needAuthorize" para mostrar o no el campo de aotoriza
     *
     */
    isAuthorize(evento) {

        for (let tipo of this.evento.local_full.tipos_pago) {

            if (tipo.id == this.form.idTipoPago) {
                this.needAuthorize = tipo.need_authorize;
            }
        }
    }

    /**
     * Cierra el modal y devuelve a la vista el resultado
     *
     */
    async dismiss(result = null) {

        await this.modalCtrl.dismiss(result);
    }
}
