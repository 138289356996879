import {Component, OnInit} from '@angular/core';
import {IonItemSliding, LoadingController, ModalController, NavController, NavParams, Platform} from '@ionic/angular';

import {ApiResponse, ConectorApi, ReservaForm} from '../../../Classes/conector-api';
import {Globales} from '../../../Classes/globales';
import {UserService} from '../../../Services/user-service.service';
import {ModalAddConsumoPage} from '../modal-add-consumo/modal-add-consumo.page';

@Component({
    selector: 'app-modal-consumo',
    templateUrl: './modal-consumo.page.html',
    styleUrls: ['./modal-consumo.page.scss'],
})
export class ModalConsumoPage implements OnInit {

    user: any;
    reserva: any;

    constructor(
        private navParams: NavParams,
        private navCtrl: NavController,
        private loadCtrl: LoadingController,
        private modalCtrl: ModalController,
        public platform: Platform,
        private conectorApi: ConectorApi,
        private gl: Globales,
        private userService: UserService,
    ) {
    }

    ngOnInit() {

        this.user = this.userService.get();
        this.reserva = this.navParams.get('item');
    }


    ionViewDidEnter() {

        this.getConsumo(null);
    }


    /**
     * Funcion que se ejecuta cuando se hace la accion pull to refresh
     *
     */
    doRefresh(refresher) {

        this.getConsumo(refresher);
    }


    /**
     * Recupera y guarda los locales
     *
     */
    async getConsumo(refresher = null) {

        this.conectorApi.getConsumoReservadoVendido(this.user, this.reserva.id).then(
            (result: ApiResponse) => {


                if (result.status === 1) {

                    this.reserva.consumo = result.data;
                }

                refresher ? refresher.target.complete() : refresher = null;

            }, (err) => {


                console.log('error');
                this.gl.showToast(err.mensaje, err.duracion);
                refresher ? refresher.target.complete() : refresher = null;

            });
    }


    /**
     * Elimina una linea de consumo
     *
     */
    async deleteCOnsumo(item: IonItemSliding, data) {

        item.close();

        if (this.gl.checkPermissions('consumo_reserva', 'borrar')) {
            const loading = await this.loadCtrl.create({});
            loading.present();

            this.conectorApi.deleteConsumoReservadoVendido(this.user, {id: data.id}).then(
                (result: ApiResponse) => {

                    if (result.status === 1) {

                        const index = this.reserva.consumo.indexOf(data);
                        this.reserva.consumo.splice(index, 1);
                    }

                    loading.dismiss();
                    this.gl.showToast(result.mensaje, result.duracion);

                }, (err) => {

                    loading.dismiss();
                    this.gl.showToast(err.mensaje, err.duracion);

                });
        }
    }


    /**
     * Abre el modal de edicion de una reserva
     *
     */
    async addConsumo() {


        const modal = await this.modalCtrl.create({
            component: ModalAddConsumoPage,
            componentProps: {item: this.reserva}
        });

        modal.onDidDismiss().then((resultado) => {

            if (resultado.data) {

                this.getConsumo();
            }
        });

        if (this.gl.checkPermissions('consumo_reserva', 'anadir')) {
            return await modal.present();
        }
    }


    /**
     * Cierra el modal y devuelve a la vista el resultado
     *
     */
    async dismiss() {

        await this.modalCtrl.dismiss(true);
    }
}
