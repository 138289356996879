import {Component, OnInit} from '@angular/core';
import {AlertController, LoadingController, ModalController, NavController, NavParams, Platform} from '@ionic/angular';

import {ApiResponse, ConectorApi, ReservaForm} from '../../../Classes/conector-api';
import {Globales} from '../../../Classes/globales';
import {UserService} from '../../../Services/user-service.service';

@Component({
    selector: 'app-modal-add-consumo',
    templateUrl: './modal-add-consumo.page.html',
    styleUrls: ['./modal-add-consumo.page.scss'],
})
export class ModalAddConsumoPage implements OnInit {

    user: any;
    bucadorInput: any;
    reserva: any;
    productosOriginal: any;
    productos: any;

    constructor(
        private navParams: NavParams,
        private navCtrl: NavController,
        private loadCtrl: LoadingController,
        private modalCtrl: ModalController,
        private alertController: AlertController,
        public platform: Platform,
        private conectorApi: ConectorApi,
        private gl: Globales,
        private userService: UserService,
    ) {
    }

    ngOnInit() {

        this.user = this.userService.get();
        this.reserva = this.navParams.get('item');
    }


    ionViewDidEnter() {

        this.getProductos(null);
    }


    /**
     * Funcion que se ejecuta cuando se hace la accion pull to refresh
     *
     */
    doRefresh(refresher) {

        this.getProductos(refresher);
    }


    /**
     * Recupera y guarda los locales
     *
     */
    async getProductos(refresher = null) {

        this.conectorApi.getProductos(this.user, this.reserva.id_sala).then(
            (result: ApiResponse) => {


                if (result.status === 1) {

                    this.productosOriginal = result.data;
                    this.productos = result.data;
                }

                refresher ? refresher.target.complete() : refresher = null;

            }, (err) => {


                console.log('error');
                this.gl.showToast(err.mensaje, err.duracion);
                refresher ? refresher.target.complete() : refresher = null;

            });
    }


    async confirmConsumo(item) {


        const alert = await this.alertController.create({
            header: 'Confirmar precio',
            inputs: [
                {
                    name: 'dato',
                    type: 'number',
                    value: item.precio,
                },
            ],
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                    }
                }, {
                    text: 'Guardar',
                    handler: (data) => {

                        if (data.dato) {
                            this.addConsumo(item, data.dato);
                        }
                    }
                }
            ]
        });
        await alert.present();
    }


    /**
     * Añade una linea de consumo
     *
     */
    async addConsumo(item, precio) {

        const loading = await this.loadCtrl.create({
            translucent: false,
        });

        loading.present();

        if (item) {

            this.conectorApi.setConsumoReservadoVendido(this.user, {id: this.reserva.id, idProducto: item.id, precio: precio}).then(
                (result: ApiResponse) => {

                    if (result.status === 1) {
                        this.dismiss();
                        this.gl.showToast(result.mensaje, result.duracion, 'success');
                    } else {
                        this.gl.showToast(result.mensaje, result.duracion);
                    }
                    loading.dismiss();
                },
                () => {

                    loading.dismiss();
                });

        } else {

            loading.dismiss();
            this.gl.showToast('Introduce todos los datos obligatorios', 2000);
        }
    }


    /**
     * Resetea los itmes
     *
     */
    onCancel(ev: any) {

        this.productos = this.productosOriginal;
    }


    /**
     * Devuelve los items filtrados
     *
     */
    getItems(ev: any) {

        this.productos = this.productosOriginal;

        let val = ev.target.value;

        if (val && val.trim() !== '') {
            this.productos = this.productos.filter((item) => {
                return item.nombre.toLowerCase().includes(val.toLowerCase());
            });
        }
    }


    /**
     * Cierra el modal y devuelve a la vista el resultado
     *
     */
    async dismiss() {

        await this.modalCtrl.dismiss(true);
    }
}
