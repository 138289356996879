import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'smd-ocupacion-zonas',
    templateUrl: './ocupacion-zonas.component.html',
    styleUrls: ['./ocupacion-zonas.component.scss'],
})
export class OcupacionZonasComponent implements OnInit {

    @Input() evento: any;
    @Input() idZona: number;
    @Input() idZonas: any;

    @Output() idZonaChange = new EventEmitter<number>();

    constructor() {

        if (this.evento && this.evento.datos && this.idZonas && this.idZonas.length > 0) {

            console.log('yeah');
        }
    }

    ngOnInit() {
    }

    setFiltroZona(id) {

        if (this.idZona != id) {

            this.idZona = id;

        } else {

            this.idZona = 0;
        }
        this.idZonaChange.emit(this.idZona);
    }
}
