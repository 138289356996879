import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {FileTransfer, FileUploadOptions, FileTransferObject} from '@ionic-native/file-transfer/ngx';
import {File} from '@ionic-native/file/ngx';

@Injectable()

export class ConectorApi {

    // api: any = 'http://smd.test/api/';
    api: any = 'https://api.dev.vip.listagruposalamandra.com/api/';
    app: any = 2 + '/';
    errorBaseText: any = 'Se ha perdido la conexion con el servidor.';
    errorBaseTime = 2000;
    fileTransfer: FileTransferObject = this.transfer.create();

    constructor(
        private http: HttpClient,
        private transfer: FileTransfer,
        private file: File
    ) {
    }

    /**
     * Realiza la accion de Login
     *
     */
    async login(form: LoginForm) {

        return new Promise((resolve, reject) => {

            this.http.post(this.api + 'login', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Realiza la accion de registro
     *
     */
    async register(form: LoginForm) {

        return new Promise((resolve, reject) => {

            this.http.post(this.api + 'register', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Devuelve los locales de un usuario
     *
     */
    async getLocales(user, filters = null) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getLocales', filters)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Devuelve un local por su ID
     *
     */
    async getLocal(user, idLocal) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getLocal', {id: idLocal})
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Devuelve los eventos de las salas que el usuario pertenece
     *
     */
    async getEventos(user, filtros) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getEventosForVip', filtros)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Devuelve el eventos por su ID
     *
     */
    async getEvento(user, idEvento) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getEventoforVip', {id: idEvento})
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }

    /**
     * Devuelve los reservados de un evento
     *
     */
    async getReservadosEvento(user, idEvento) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getReservadosEventoVip', {id: idEvento})
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Devuelve las notificaciones
     *
     */
    async getNotificaciones(user, filtros) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getNotificaciones', filtros)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Edita una notificacion
     *
     */
    async setNotificacion(user, notificacion: NotificationForm) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'setNotificacion', notificacion)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Elimina una notificacion
     *
     */
    async deleteNotificacion(user, notificacion: NotificationForm) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'deleteNotificacion', notificacion)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Elimina una notificacion
     *
     */
    async setLeidasNotificaciones(user, leidas = 1) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'setLeidasNotificaciones', {read: leidas})
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }

    /**
     * Elimina una notificacion
     *
     */
    async deleteNotificaciones(user, eliminar = 1) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'deleteNotificaciones', {delete: eliminar})
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Devuelve el listado de ranking de usuarios
     *
     */
    async getRanking(user, filtros) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getRankingPro', filtros)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Devuelve el listado de ranking de usuarios
     *
     */
    async getHistoricos(user) {

        return new Promise((resolve, reject) => {

            this.http.get(user.api + this.app + 'getHistoricos')
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Devuelve las solicitudes de los reservados de un usuario
     *
     */
    async getSolicitudesReservados(user, filtros) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getSolicitudesReservados', filtros)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Envia el formulario de solicitud
     *
     */
    async setSolicitudReservado(user, form: ReservaForm) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'setSolicitudReservado', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }

    /**
     * Edita la solicitud
     *
     */
    async editSolicitudReservado(user, form: ReservaForm) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'editSolicitudReservado', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Envia un mensaje a una solicitud de reserva
     *
     */
    async setMensajeSolicitudReservado(user, form: MensajeSolicitudForm) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'setMensajeSolicitud', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }

    /**
     * Recupera todos los mensajes de una solicitud
     *
     */
    async getMensajesSolicitud(user, form: MensajeSolicitudForm) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getMensajesSolicitud', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Recupera todos los mensajes de una solicitud
     *
     */
    async cambioEstadoSolicitud(user, form: ReservaForm) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'cambioEstadoSolicitud', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }

    /**
     * Recupera todos los mensajes de una solicitud
     *
     */
    async checkReserva(user, form: ReservaForm) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'checkReserva', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Edita los datos de una reserva
     *
     */
    async editMesaReservado(user, form: ReservaForm) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'editMesaReservado', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Email de confirmacion a una reserva
     *
     */
    async sendEmailConfirmacion(user, form: ReservaForm) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'sendEmailConfirmacion', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Email de confirmacion a todas las reservas de un evento
     *
     */
    async sedEmailConfirmacionToAll(user, form: EventoForm) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'sedEmailConfirmacionToAll', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Email de informe de un evento
     *
     */
    async sendEmailInformeEvento(user, form: EventoForm) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'sendEmailInformeEvento', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Bloquea una mesa para un evento
     *
     */
    async bloquearReservadoEvento(user, form: BloqueoReservado) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'bloquearReservadoEvento', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Bloquea una zona para un evento
     *
     */
    async bloquearZonaEvento(user, form: BloqueoZona) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'bloquearZonaEvento', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }

    /**
     * Recupera todos los mensajes de una solicitud
     *
     */
    async deleteSolicitud(user, form: ReservaForm) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'deleteSolicitud', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }

    /**
     * Recupera todos los mensajes de una solicitud
     *
     */
    async setReservadoImportante(user, form: ReservaForm) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'setReservadoImportante', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Elimina la imagen de perfil
     *
     */
    async deleteImegenPerfil(user) {

        return new Promise((resolve, reject) => {

            this.http.get(user.api + this.app + 'deleteImegenPerfil')
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Guarda la imagen de perfil
     *
     */
    async setImagenPerfil(user, path) {

        return new Promise((resolve, reject) => {


            const options: FileUploadOptions = {
                fileKey: 'file'
            };

            this.fileTransfer.upload(path, user.api + this.app + 'setImagenPerfil', options)
                .then((respuesta) => {

                    resolve(respuesta);

                }, (err) => {

                    const error: ApiResponse = {
                        status: 0,
                        mensaje: this.errorBaseText,
                        duracion: this.errorBaseTime,
                        data: err
                    };
                    reject(error);
                });
        });
    }

    /**
     * Deveuelve los langs
     *
     */
    async getLangs(user) {

        return new Promise((resolve, reject) => {

            this.http.get(user.api + this.app + 'getLangs')
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Deveuelve los langs
     *
     */
    async getUsuarios(user) {

        return new Promise((resolve, reject) => {

            this.http.get(user.api + this.app + 'getUsuarios')
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Deveuelve la sesion del usuario renovada
     *
     */
    async getFullSession(user) {

        return new Promise((resolve, reject) => {

            this.http.get(user.api + this.app + 'getFullSession')
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Devuelve los productos de una sala
     *
     */
    async getProductos(user, idSala) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getProductos', {id: idSala})
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Devuelve los productos de una sala
     *
     */
    async getConsumoReservadoVendido(user, idReservado) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'getConsumoReservadoVendido', {id: idReservado})
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }

    /**
     * Añade el consumo a una mesa
     *
     */
    async setConsumoReservadoVendido(user, form) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'setConsumoReservadoVendido', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Elimina el consumo a una mesa
     *
     */
    async deleteConsumoReservadoVendido(user, idReservado) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'deleteConsumoReservadoVendido', {id: idReservado})
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * cambiar la contraseña
     *
     */
    async setNewPassword(user, form) {

        return new Promise((resolve, reject) => {

            this.http.post(user.api + this.app + 'setNewPassword', form)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }


    /**
     * Recupera una accion libre
     *
     */
    async getAccionLibre(user, apiAction) {

        return new Promise((resolve, reject) => {

            this.http.get(user.api + this.app + apiAction)
                .subscribe(
                    (respuesta: ApiResponse) => {

                        resolve(respuesta);
                    },
                    err => {

                        const error: ApiResponse = {
                            status: 0,
                            mensaje: this.errorBaseText,
                            duracion: this.errorBaseTime,
                            data: err
                        };
                        reject(error);
                    }
                );
        });
    }
}

export interface ApiResponse {
    status: number;
    mensaje: string;
    duracion: number;
    data: any;
}

export interface LoginForm {
    email: string;
    pass: string;
    app: number;
    platform: string;
    push?: string;
    lang?: string;
}

export interface RegisterForm {
    nombre: string;
    apellidos: string;
    email: string;
    pass: string;
    pass2: string;
    app: number;
    platform: string;
    push: string;
    lang: string;
}

export interface ReservaForm {
    id?: number;
    idLang?: number | string | null;
    idEvento?: number | null;
    idReservado?: number | null;
    tipoCambioReservado?: number | null;
    idTipoPago?: number | null;
    idUsuario?: number | null;
    nombre?: string;
    aceptado?: number;
    email?: string;
    pax?: number | null;
    precio?: number | null;
    autoriza?: string | null;
    comentarios?: string | null;
    forzar?: number | boolean;
}

export interface EventoForm {
    id?: number;
}

export interface NotificationForm {
    id: number;
    vista?: boolean;
}

export interface MensajeSolicitudForm {
    id_solicitud: number;
    mensaje?: string;
}

export interface BloqueoReservado {
    id_reservado: number;
    id_evento: number;
}

export interface BloqueoZona {
    id_zona: number;
    id_evento: number;
}



