import {NgModule} from '@angular/core';
import {EventosPipe} from './Eventos/eventos.pipe';
import {MesasPipe} from './Reservas/mesas.pipe';
import {OrdenPipe} from './orden.pipe';
import {ReservasPipe} from './Reservas/reservas.pipe';
import {ZonaPipe} from './Eventos/zona.pipe';

@NgModule({
    imports: [],
    declarations: [EventosPipe, MesasPipe, OrdenPipe, ReservasPipe, ZonaPipe],
    exports: [EventosPipe, MesasPipe, OrdenPipe, ReservasPipe, ZonaPipe]
})
export class PipesModule {
}
