import {Component, OnInit} from '@angular/core';
import {
    ActionSheetController,
    IonItemSliding,
    LoadingController,
    ModalController,
    NavController,
    NavParams,
    Platform
} from '@ionic/angular';
import {ApiResponse, ConectorApi, ReservaForm} from '../../../Classes/conector-api';
import {Globales} from '../../../Classes/globales';
import {UserService} from '../../../Services/user-service.service';

import {PhotoViewer} from '@ionic-native/photo-viewer/ngx';
import {ModalChatSolicitudReservadoPage} from '../modal-chat-solicitud-reservado/modal-chat-solicitud-reservado.page';
import {ModalConsumoPage} from '../modal-consumo/modal-consumo.page';
import {Eventos} from '../../../Classes/eventos';

@Component({
    selector: 'app-modal-reservas-mesa',
    templateUrl: './modal-reservas-mesa.page.html',
    styleUrls: ['./modal-reservas-mesa.page.scss'],
})
export class ModalReservasMesaPage implements OnInit {

    user: any;
    mesa: any;
    evento: any;
    ventas: any;
    reservados: any;
    zonas: any;

    constructor(
        private navParams: NavParams,
        private navCtrl: NavController,
        private loadCtrl: LoadingController,
        private modalCtrl: ModalController,
        private actionSheetController: ActionSheetController,
        public platform: Platform,
        private conectorApi: ConectorApi,
        private photoViewer: PhotoViewer,
        private gl: Globales,
        private ev: Eventos,
        private userService: UserService,
    ) {

        this.user = this.userService.get();
        this.mesa = this.navParams.get('mesa');
        this.evento = this.navParams.get('evento');
        this.ventas = this.evento.ventas;
    }

    ngOnInit() {

        this.getReservados();
    }


    /**
     * Recupera el evento por su ID
     *
     */
    async getEvento(refresher = null) {


        this.conectorApi.getEvento(this.user, this.evento.id).then(
            (result: ApiResponse) => {

                if (result.status === 1) {

                    this.evento = result.data;
                    this.ventas = this.evento.ventas;
                }

                refresher ? refresher.target.complete() : refresher = null;

            }, (err) => {


                console.log('error');
                this.gl.showToast(err.mensaje, err.duracion);
                refresher ? refresher.target.complete() : refresher = null;

            });
    }

    /**
     * Recupera los reservados libres
     *
     */
    async getReservados(refresher = null) {

        this.conectorApi.getReservadosEvento(this.user, this.evento.id).then(
            (result: ApiResponse) => {

                this.reservados = result.data.mesas;
                this.zonas = result.data.zonas;
                refresher ? refresher.target.complete() : refresher = null;
            }
        );
    }

    /***************************************************************************************************************************************
     **** ACTION SHEETS *******************************************************************************************************************/


    /**
     * abre las opciones de una reserva cancelada
     */
    async masOpcionesCanceladas(item: IonItemSliding, data) {

        item.close();

        const actionSheet = await this.actionSheetController.create({
            header: 'Opciones reserva',
            buttons: [
                {
                    text: 'Abrir conversación',
                    handler: () => {
                        this.abreCharReserva(null, data);
                    }
                }, {
                    text: 'Aceptar solicitud',
                    handler: () => {
                        this.estadoReserva(null, data, 1);
                    }
                }, {
                    text: 'Poner en espera',
                    handler: () => {
                        this.estadoReserva(null, data, 0);
                    }
                }, {
                    text: 'Cancelar',
                    role: 'cancel',
                    handler: () => {
                    }
                }]
        });
        await actionSheet.present();
    }

    /**
     * abre las opciones de una reserva Aceptada
     *
     */
    async masOpcionesAceptadas(item: IonItemSliding, data) {

        item.close();

        const actionSheet = await this.actionSheetController.create({
            header: 'Opciones reserva',
            buttons: [
                {
                    text: 'Cambio de mesa',
                    handler: () => {

                        this.editarMesaReserva(null, data);
                    }
                },
                {
                    text: 'Cambiar relaciones publicas',
                    handler: () => {

                        this.editarRelacionesPublicas(null, data);
                    }
                }, {
                    text: 'Ver/Editar datos',
                    handler: () => {
                        this.editarReserva(null, data);
                    }
                }, {
                    text: 'Checking',
                    handler: () => {
                        this.checkingReserva(null, data);
                    }
                }, {
                    text: 'Poner en espera',
                    handler: () => {
                        this.estadoReserva(null, data, 0);
                    }
                }, {
                    text: 'Cancelar reserva',
                    handler: () => {
                        this.estadoReserva(null, data, 2);
                    },

                }
                , {
                    text: 'Cancelar',
                    role: 'cancel',
                    handler: () => {
                    }
                }]
        });
        await actionSheet.present();
    }

    /**
     * abre las opciones de una reserva aceptada y con el checking
     */
    async masOpcionesChecking(item: IonItemSliding, data) {

        item.close();

        const actionSheet = await this.actionSheetController.create({
            header: 'Opciones reserva',
            buttons: [
                {
                    text: 'Añadir consumo',
                    handler: () => {
                        this.addConsumo(null, data);
                    }
                }, {
                    text: 'Ver consumo',
                    handler: () => {
                        this.verConsumo(null, data);
                    }
                }, {
                    text: 'Cambio de mesa',
                    handler: () => {

                        this.editarMesaReserva(null, data);
                    }
                }, {
                    text: 'Cambiar relaciones publicas',
                    handler: () => {

                        this.editarRelacionesPublicas(null, data);
                    }
                }, {
                    text: 'Cerrar mesa',
                    handler: () => {

                        this.estadoReserva(null, data, 4);
                    }
                }, {
                    text: 'Cancelar',
                    role: 'cancel',
                    handler: () => {
                    }
                }]
        });
        await actionSheet.present();
    }

    /***************************************************************************************************************************************
     **** FUNCIONES EVENTO  ***************************************************************************************************************/

    /**
     * Abre el modal de chat de una resrva
     *
     */
    async abreCharReserva(item: IonItemSliding = null, data) {

        if (item) {
            item.close();
        }

        const modal = await this.modalCtrl.create({
            component: ModalChatSolicitudReservadoPage,
            componentProps: {item: data}
        });

        modal.onDidDismiss().then((resultado) => {

            if (resultado.data) {

                // this.getEvento();
            }
        });

        return await modal.present();
    }


    /**
     * Abre el modal de edicion de una reserva
     *
     */
    async verConsumo(item: IonItemSliding = null, data) {

        if (item) {
            item.close();
        }

        const modal = await this.modalCtrl.create({
            component: ModalConsumoPage,
            componentProps: {item: data}
        });

        modal.onDidDismiss().then((resultado) => {

            if (resultado.data) {

                this.getEvento();
            }
        });

        if (this.gl.checkPermissions('consumo_reserva', 'ver')) {
            return await modal.present();
        }

    }

    /**
     * Abre para confirmar el numero de gente que hay en la reseva antes de hacer el checking
     * Con la clase Eventos
     *
     */
    async checkingReserva(item: IonItemSliding = null, data) {

        if (item) {
            item.close();
        }

        this.ev.confirmCheckingReserva(data).then((result) => {

            if (result) {
                this.getEvento();
            }
        });
    }

    /**
     * cambia el estado de una reserva
     * Con la clase Eventos
     *
     */
    async estadoReserva(item: IonItemSliding = null, data, estado) {

        if (item) {
            item.close();
        }

        this.ev.estadoReserva(data, estado).then((result) => {

            if (result) {
                this.getReservados();
                this.getEvento();
            }
        });
    }


    /**
     * Abre el modal de edicion de una reserva
     * Con la clase Eventos
     */
    async editarReserva(item: IonItemSliding = null, data) {

        if (item) {
            item.close();
        }

        this.ev.editarReserva(data).then((result) => {

            this.getEvento();
        });
    }

    /**
     * Abre modal selector para cambiar relacciones publicas
     * Con la clase Eventos
     *
     */
    async editarRelacionesPublicas(item: IonItemSliding, data) {

        if (item) {
            item.close();
        }

        this.ev.editarRelacionesPublicas(data).then((result) => {

            if (result) {

                this.getEvento();
            }
        });
    }

    /**
     * Abre el modal para la edicion de la reserva
     * Con la clase Eventos
     *
     */
    async editarMesaReserva(item: IonItemSliding, data) {

        if (item) {
            item.close();
        }

        console.log(this.reservados);
        console.log(this.zonas);


        this.ev.editarMesaReserva(data, this.reservados, this.zonas).then((result) => {

            if (result) {

                this.ev.setMesaReserva(data, result.id, result.tipo).then((res) => {

                    if (res) {
                        this.getReservados();
                        this.getEvento();
                    }
                });
            }
        });
    }

    /**
     * Abre el modal de edicion de una reserva
     * Con la clase Eventos
     *
     */
    async addConsumo(item: IonItemSliding, data) {

        if (item) {
            item.close();
        }

        this.ev.addConsumo(data).then((result) => {

            if (result) {

                this.getEvento();
            }
        });
    }


    /**
     * Abre la imagen de la reserva
     *
     */
    abreImagen(item) {
        if (item.archivo) {
            const options = {share: true, closeButton: false};
            this.photoViewer.show(item.archivo, 'Archivo reserva');

        } else {
            this.gl.showToast('Imagen no disponible', 2000);
        }
    }

    /**
     * Cierra el modal y devuelve a la vista el resultado
     *
     */
    async dismiss() {

        await this.modalCtrl.dismiss();
    }
}
