import {Component, OnInit} from '@angular/core';
import {LoadingController, ModalController, NavController, NavParams, Platform} from '@ionic/angular';

// import {StatusBar} from '@ionic-native/status-bar/ngx';

import {ConectorApi, RegisterForm, ApiResponse} from '../../../Classes/conector-api';
import {Globales} from '../../../Classes/globales';
import {UserService} from '../../../Services/user-service.service';

@Component({
    selector: 'app-modal-register',
    templateUrl: './modal-register.page.html',
    styleUrls: ['./modal-register.page.scss'],
})
export class ModalRegisterPage implements OnInit {

    form: RegisterForm;
    pushToken: any;

    constructor(
        private navParams: NavParams,
        private navCtrl: NavController,
        // private statusBar: StatusBar,
        private loadCtrl: LoadingController,
        private modalCtrl: ModalController,
        public platform: Platform,
        private conectorApi: ConectorApi,
        private gl: Globales,
        private userService: UserService,
    ) {

        const platfotm = this.platform.is('ios') ? 'ios' : 'android';
        this.pushToken = this.navParams.get('pushToken');
        this.form = {
            nombre: '',
            apellidos: '',
            email: '',
            pass: '',
            pass2: '',
            app: 2,
            platform: platfotm,
            push: this.pushToken,
            lang: 'es'
        };
    }

    ngOnInit() {
    }

    /**
     * Funcion que se ejecuta despues de entrar en la vista
     */
    ionViewDidEnter() {

        // this.statusBar.styleDefault();
    }

    /**
     * Funcio que se ejecuta despues de dejar la vista.
     *
     */
    ionViewDidLeave() {

        // this.statusBar.styleLightContent();
    }

    /**
     * Cierra el modal y devuelve a la vista el resultado
     *
     */
    async dismiss(result = null) {

        await this.modalCtrl.dismiss(result);
    }

    /**
     * Realiza la accion de registro
     *
     */
    async doRegister() {

        const loading = await this.loadCtrl.create({
            translucent: false,
        });

        loading.present();

        this.conectorApi.register(this.form)
            .then((result: ApiResponse) => {

                this.gl.showToast(result.mensaje, result.duracion);

                if (result.status === 1) {

                    this.userService.set(result.data);
                    this.dismiss(result.data);

                    setTimeout(() => {
                        loading.dismiss();
                    }, 1000);
                } else {
                    loading.dismiss();
                }

            })
            .catch((err) => {

                loading.dismiss();
                console.log(err);
                this.gl.showToast(err.mensaje, err.duracion);

            });
    }
}
