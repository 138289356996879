import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {IonicStorageModule} from '@ionic/storage';

import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {PhotoViewer} from '@ionic-native/photo-viewer/ngx';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {Push} from '@ionic-native/push/ngx';
import {Camera} from '@ionic-native/camera/ngx';
import {Crop} from '@ionic-native/crop/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {File} from '@ionic-native/file/ngx';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {ModalLoginPage} from './Pages/Auth/modal-login/modal-login.page';
import {ModalRegisterPage} from './Pages/Auth/modal-register/modal-register.page';
import {ModalSolicitarReservadoPage} from './Pages/Eventos/modal-solicitar-reservado/modal-solicitar-reservado.page';
import {ModalEventoMesasPage} from './Pages/Eventos/modal-evento-mesas/modal-evento-mesas.page';
import {ModalChatSolicitudReservadoPage} from './Pages/Eventos/modal-chat-solicitud-reservado/modal-chat-solicitud-reservado.page';
import {ModalEditarSolicitudReservadoPage} from './Pages/Eventos/modal-editar-solicitud-reservado/modal-editar-solicitud-reservado.page';
import {ModalCambioMesaPage} from './Pages/Eventos/modal-cambio-mesa/modal-cambio-mesa.page';
import {ModalFiltrosReservasPage} from './Pages/Eventos/modal-filtros-reservas/modal-filtros-reservas.page';
import {ModalConsumoPage} from './Pages/Eventos/modal-consumo/modal-consumo.page';
import {ModalAddConsumoPage} from './Pages/Eventos/modal-add-consumo/modal-add-consumo.page';
import {ModalReservasMesaPage} from './Pages/Eventos/modal-reservas-mesa/modal-reservas-mesa.page';
import {ModalEditarRelacionesPublicasPage} from './Pages/Eventos/modal-editar-relaciones-publicas/modal-editar-relaciones-publicas.page';

import {ConectorApi} from './Classes/conector-api';
import {Globales} from './Classes/globales';
import {IonicEvents} from './Classes/IonicEvents';
import {Eventos} from './Classes/eventos';

import {UserService} from './Services/user-service.service';
import {LocalesService} from './Services/locales.service';
import {EventosService} from './Services/eventos.service';
import {EventosStorageService} from './Services/eventosV2.service';
import {HistoricosStorageService} from './Services/historicosV2.service';
import {HistoricosService} from './Services/historicos.service';
import {NotificacionesService} from './Services/notificaciones.service';
import {RankingService} from './Services/ranking.service';
import {ReservasService} from './Services/reservas.service';
import {LangsService} from './Services/langs.service';
import {UsuariosService} from './Services/usuarios.service';

import {PipesModule} from './Pipes/pipes.module';
import {ComponentsModule} from './Components/components.module';

import es from '@angular/common/locales/es';
import {registerLocaleData} from '@angular/common';
import {EventoPageModule} from './Pages/Eventos/evento/evento.module';

registerLocaleData(es);

@NgModule({
    declarations: [
        AppComponent,
        ModalLoginPage,
        ModalRegisterPage,
        ModalSolicitarReservadoPage,
        ModalEventoMesasPage,
        ModalChatSolicitudReservadoPage,
        ModalEditarSolicitudReservadoPage,
        ModalFiltrosReservasPage,
        ModalCambioMesaPage,
        ModalConsumoPage,
        ModalAddConsumoPage,
        ModalReservasMesaPage,
        ModalEditarRelacionesPublicasPage,
    ],
    entryComponents: [
        ModalLoginPage,
        ModalRegisterPage,
        ModalSolicitarReservadoPage,
        ModalEventoMesasPage,
        ModalChatSolicitudReservadoPage,
        ModalEditarSolicitudReservadoPage,
        ModalFiltrosReservasPage,
        ModalCambioMesaPage,
        ModalConsumoPage,
        ModalAddConsumoPage,
        ModalReservasMesaPage,
        ModalEditarRelacionesPublicasPage,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        PipesModule,
        ComponentsModule,
        IonicModule.forRoot({
            backButtonText: 'Atras',
        }),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        EventoPageModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        PhotoViewer,
        SocialSharing,
        SplashScreen,
        Push,
        Camera,
        Crop,
        FileTransfer,
        File,
        BarcodeScanner,
        FileOpener,

        ConectorApi,
        Globales,
        IonicEvents,
        Eventos,
        UserService,
        LocalesService,
        EventosService,
        EventosStorageService,
        HistoricosService,
        HistoricosStorageService,
        NotificacionesService,
        RankingService,
        ReservasService,
        LangsService,
        UsuariosService,
        {provide: LOCALE_ID, useValue: 'es'},
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
