import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {LoadingController, ModalController, NavController, AlertController, NavParams, Platform} from '@ionic/angular';
import {ApiResponse, ConectorApi, ReservaForm} from '../../../Classes/conector-api';
import {Globales} from '../../../Classes/globales';
import {UserService} from '../../../Services/user-service.service';

@Component({
    selector: 'app-modal-cambio-mesa',
    templateUrl: './modal-cambio-mesa.page.html',
    styleUrls: ['./modal-cambio-mesa.page.scss'],
})
export class ModalCambioMesaPage implements OnInit {

    user: any;
    reserva: any;
    reservados: any;
    filtros: any;
    zonas: any;
    form: ReservaForm;

    constructor(
        private zone: NgZone,
        private navParams: NavParams,
        private navCtrl: NavController,
        private loadCtrl: LoadingController,
        private modalCtrl: ModalController,
        private alertCtrl: AlertController,
        public platform: Platform,
        private conectorApi: ConectorApi,
        private gl: Globales,
        private userService: UserService,
    ) {
        this.getFiltros();

        this.user = this.userService.get();
        this.reserva = this.navParams.get('item');
        this.reservados = this.navParams.get('mesas');
        this.zonas = this.navParams.get('zonas');

        this.form = {
            id: this.reserva.id,
            idReservado: this.reserva.id_reservado,
        };
    }

    ngOnInit() {
    }


    /**
     *
     */
    getFiltros() {
        this.filtros = {
            id_zona: null
        };
    }

    /**
     *
     */
    setFiltroZona(id) {


        this.zone.run(() => {

            if (id === this.filtros.id_zona) {

                this.filtros.id_zona = null;

            } else {

                this.filtros.id_zona = id;
            }
        });
    }

    /**
     * Selecciona una mesa
     *
     */
    async selecionarMesa(item) {

        if (item.bloqueado_web) {

            this.gl.showToast('Esta mesa esta bloqueada para venta web y no se puede utilizar', 6000);
            return false;
        }

        if (!item.bloqueado) {
            const alert = await this.alertCtrl.create({
                header: 'Confirmar cambio!',
                message: 'Quieres cambiar la reserva de la mesa "' + this.reserva.reservado.nombre + '" a la mesa "' + item.nombre + '"',
                buttons: [
                    {
                        text: 'Cancelar',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: (blah) => {

                        }
                    }, {
                        text: 'Si, cambiar',
                        handler: () => {

                            if (item.libre) {

                                item.tipo = 0;
                                this.dismiss(item);

                            } else {

                                this.slectTipoCambio(item);
                            }
                        }
                    }
                ]
            });

            await alert.present();

        } else {

            this.gl.showToast('Esta mesa esta bloqueada y no se puede utilizar', 3000);

            const alert = await this.alertCtrl.create({
                header: 'Mesa bloqueada',
                message: 'Esta mesa esta bloqueada. Quieres realizar el cambio igualmente de la mesa "' + this.reserva.reservado.nombre + '" a la mesa "' + item.nombre + '"?',
                buttons: [
                    {
                        text: 'Cancelar',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: (blah) => {

                        }
                    }, {
                        text: 'Si, cambiar',
                        handler: () => {

                            if (item.libre) {

                                item.tipo = 0;
                                this.dismiss(item);

                            } else {

                                this.slectTipoCambio(item);
                            }
                        }
                    }
                ]
            });

            await alert.present();
        }
    }


    async slectTipoCambio(item) {

        const alert = await this.alertCtrl.create({
            header: 'Mesa destino ocupada!',
            message: 'Tienes tres opciones para el cambio de mesa:',
            buttons: [
                {
                    text: 'Intercambiar mesas',
                    handler: () => {
                        item.tipo = 1;
                        this.dismiss(item);
                    }
                }
                , {
                    text: 'Combinar mesas en destino',
                    handler: () => {
                        item.tipo = 2;
                        this.dismiss(item);
                    }
                }, {
                    text: 'Cerrar mesa destino y cambiar',
                    handler: () => {
                        item.tipo = 3;
                        this.dismiss(item);
                    }
                }, {
                    text: 'Poner espera mesa destino y cambiar',
                    handler: () => {
                        item.tipo = 4;
                        this.dismiss(item);
                    }
                },
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {

                    }
                }
            ]
        });

        await alert.present();
    }


    /**
     * Cierra el modal y devuelve a la vista el resultado
     *
     */
    async dismiss(result = null) {

        await this.modalCtrl.dismiss(result);
    }
}
