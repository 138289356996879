import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';

@Injectable()

export class HistoricosStorageService {


    tabla = 'smd_vp_historicos_v2';
    data = [];

    constructor(private storage: Storage) {
    }


    get(ID = null): Promise<any> {

        return this.storage.get(this.tabla).then((val) => {

            if (ID) {

                const aux = JSON.parse(val);

                return aux.find((item) => {
                    return item.id == ID;
                });

            } else {

                return JSON.parse(val);
            }
        });

    }


    set(data): Promise<any> {

        this.data = data;

        return this.storage.set(this.tabla, JSON.stringify(data)).then((val) => {

            return this.data;
        });

    }


    delete() {

        this.data = null;
        this.storage.remove(this.tabla);

    }

}
