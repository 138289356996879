import {Component, OnInit} from '@angular/core';
import {LoadingController, ModalController, NavController, NavParams, Platform} from '@ionic/angular';
import {ApiResponse, ConectorApi, ReservaForm} from '../../../Classes/conector-api';
import {Globales} from '../../../Classes/globales';
import {UserService} from '../../../Services/user-service.service';
import {LangsService} from '../../../Services/langs.service';

@Component({
    selector: 'app-modal-editar-solicitud-reservado',
    templateUrl: './modal-editar-solicitud-reservado.page.html',
    styleUrls: ['./modal-editar-solicitud-reservado.page.scss'],
})
export class ModalEditarSolicitudReservadoPage implements OnInit {

    user: any;
    langs: any;
    form: ReservaForm;
    reserva: any;

    constructor(
        private navParams: NavParams,
        private navCtrl: NavController,
        private loadCtrl: LoadingController,
        private modalCtrl: ModalController,
        public platform: Platform,
        private conectorApi: ConectorApi,
        private gl: Globales,
        private userService: UserService,
        private langsService: LangsService,
    ) {

        this.user = this.userService.get();
        this.langs = this.langsService.get();
        this.reserva = this.navParams.get('item');
        this.form = {
            id: this.reserva.id,
            idLang: (this.reserva.id_lang).toString(),
            nombre: this.reserva.nombre,
            pax: this.reserva.pax,
            precio: this.reserva.precio,
            email: this.reserva.email
        };
    }

    ngOnInit() {
    }


    /**
     * Guarda los datos de una solicitud
     *
     */
    async setSolicidud() {

        const loading = await this.loadCtrl.create({
            translucent: false,
        });

        loading.present();

        if (this.form.nombre && this.form.pax) {


            this.conectorApi.editSolicitudReservado(this.user, this.form).then(
                (result: ApiResponse) => {

                    if (result.status === 1) {
                        this.dismiss(result.data);
                        this.gl.showToast(result.mensaje, result.duracion, 'success');
                    } else {
                        this.gl.showToast(result.mensaje, result.duracion);
                    }
                    loading.dismiss();
                },
                () => {

                    loading.dismiss();
                });
        } else {

            loading.dismiss();
            this.gl.showToast('Introduce todos los datos obligatorios', 2000);
        }
    }

    /**
     * Cierra el modal y devuelve a la vista el resultado
     *
     */
    async dismiss(result = null) {

        await this.modalCtrl.dismiss(result);
    }
}
