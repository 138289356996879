import {Component, OnInit, ViewChild} from '@angular/core';
import {LoadingController, ModalController, NavController, NavParams, Platform} from '@ionic/angular';
import {ApiResponse, ConectorApi, MensajeSolicitudForm} from '../../../Classes/conector-api';
import {Globales} from '../../../Classes/globales';
import {UserService} from '../../../Services/user-service.service';

@Component({
    selector: 'app-modal-chat-solicitud-reservado',
    templateUrl: './modal-chat-solicitud-reservado.page.html',
    styleUrls: ['./modal-chat-solicitud-reservado.page.scss'],
})
export class ModalChatSolicitudReservadoPage implements OnInit {

    user: any;
    reserva: any;
    form: MensajeSolicitudForm;
    mensaje: any;

    @ViewChild('content', {static: false}) private content: any;

    constructor(
        private navParams: NavParams,
        private navCtrl: NavController,
        private loadCtrl: LoadingController,
        private modalCtrl: ModalController,
        public platform: Platform,
        private conectorApi: ConectorApi,
        private gl: Globales,
        private userService: UserService,
    ) {
        this.user = this.userService.get();
        this.reserva = this.navParams.get('item');
        this.form = {
            id_solicitud: this.reserva.id,
            mensaje: null
        };

        this.getMensajes();
    }

    ngOnInit() {
    }


    /**
     * Funcion que se ejecuta justo despues de entrar en la vista
     *
     */
    ionViewDidEnter() {

        this.content.scrollToBottom(300);
    }


    /**
     * Funcion que se ejecuta cuando se hace la accion pull to refresh
     *
     */
    doRefresh(refresher) {

        this.getMensajes(refresher);
    }


    /**
     * Recupera y guarda los locales
     *
     */
    async getMensajes(refresher = null) {

        this.conectorApi.getMensajesSolicitud(this.user, this.form).then(
            (result: ApiResponse) => {


                if (result.status === 1) {

                    this.reserva.comunicaciones = result.data;
                    this.content.scrollToBottom(100);
                }

                refresher ? refresher.target.complete() : refresher = null;

            }, (err) => {


                console.log('error');
                this.gl.showToast(err.mensaje, err.duracion);
                refresher ? refresher.target.complete() : refresher = null;

            });
    }


    /**
     * Funcion que envia el mensaje
     *
     */
    sendMessage() {

        if (this.mensaje) {

            const now = new Date();
            const mensajeAux = this.mensaje;
            this.form.mensaje = mensajeAux;

            this.reserva.comunicaciones.push({
                id: null,
                id_usuario: this.user.id,
                usuario: this.user.name,
                usuario_imagen: null,
                visto: 0,
                fecha: now,
                mensaje: mensajeAux
            });

            this.conectorApi.setMensajeSolicitudReservado(this.user, this.form);
            this.mensaje = null;

            setTimeout(() => {
                this.content.scrollToBottom(100);
            }, 300);
        }
    }


    /**
     * Cuando se hace focus en el textarea se hace un scrolll del contenido
     *
     */
    textAreaFocus() {
        setTimeout(() => {
            this.content.scrollToBottom();
        }, 350);
    }


    /**
     * Cierra el modal y devuelve a la vista el resultado
     *
     */
    async dismiss(result = null) {

        await this.modalCtrl.dismiss(result);
    }
}
