import {Component, OnInit, NgZone, ViewChild} from '@angular/core';
import {
    LoadingController,
    ModalController,
    NavController,
    ActionSheetController,
    AlertController,
    IonItemSliding,
    IonContent,
    Platform
} from '@ionic/angular';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';

import {PhotoViewer} from '@ionic-native/photo-viewer/ngx';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import {File} from '@ionic-native/file/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';

import {ApiResponse, ConectorApi, ReservaForm} from '../../../Classes/conector-api';
import {Globales} from '../../../Classes/globales';
import {Eventos} from '../../../Classes/eventos';
import {UserService} from '../../../Services/user-service.service';
import {EventosService} from '../../../Services/eventos.service';
import {EventosStorageService} from '../../../Services/eventosV2.service';
import {UsuariosService} from '../../../Services/usuarios.service';

import {ModalSolicitarReservadoPage} from '../modal-solicitar-reservado/modal-solicitar-reservado.page';
import {ModalEventoMesasPage} from '../modal-evento-mesas/modal-evento-mesas.page';
import {ModalFiltrosReservasPage} from '../modal-filtros-reservas/modal-filtros-reservas.page';
import {ModalReservasMesaPage} from '../modal-reservas-mesa/modal-reservas-mesa.page';

@Component({
    selector: 'app-evento',
    templateUrl: './evento.page.html',
    styleUrls: ['./evento.page.scss'],
})
export class EventoPage implements OnInit {

    @ViewChild(IonContent, {read: IonContent, static: false}) myContent: IonContent;

    user: any;
    usuarios: any;
    bucadorInput: any;
    bucadorInputSalon: any;
    idEvento: any;
    idReserva: any;
    evento: any;
    ventas: any;
    reservados: any;
    reservadosAll: any;
    zonas: any;
    filtros: any = {};
    form: ReservaForm;
    menuSegment: any = 'lista';

    transfer = this.ft.create();
    progres: any;
    globalLoading: any;

    constructor(
        public zone: NgZone,
        private navCtrl: NavController,
        private loadCtrl: LoadingController,
        private modalCtrl: ModalController,
        private actionSheetController: ActionSheetController,
        private alertController: AlertController,
        private platform: Platform,
        private conectorApi: ConectorApi,
        private gl: Globales,
        private ev: Eventos,
        public file: File,
        public ft: FileTransfer,
        private fileOpener: FileOpener,
        private userService: UserService,
        private usuariosService: UsuariosService,
        private route: ActivatedRoute,
        private photoViewer: PhotoViewer,
        private socialSharing: SocialSharing,
        private barcodeScanner: BarcodeScanner,
        private eventosService: EventosStorageService,
    ) {

        this.user = this.userService.get();
        this.usuarios = this.usuariosService.get();
        this.idEvento = this.route.snapshot.paramMap.get('id');
        this.idReserva = this.platform.getQueryParam('res');

        this.filtros = {
            num_filtros: 0,
            id_estado: '0',
            id_orden: 'a',
            id_zona: 0,
            id_zonas: [],
            eliminadas: false,
            cerradas: false,
            checking: 0,
        };


        this.transfer.onProgress((progressEvent) => {

            if (progressEvent.lengthComputable) {

                let perc = Math.floor(progressEvent.loaded / progressEvent.total * 100);
                this.globalLoading.message = perc + '%';
            }
        });
    }

    ngOnInit() {
    }


    /**
     * Funcion que se ejecuta justo despues de entrar en la vista
     *
     */
    ionViewDidEnter() {

        this.eventosService.get(this.idEvento).then((data) => {

            if (!this.evento) {
                this.evento = data;
            }
        });

        this.getEvento();
        this.getReservados();
        this.idReserva = this.platform.getQueryParam('res');
        // this.scrollToReserva(this.idReserva);

    }

    /**
     * Funcion que se ejecuta cuando se hace la accion pull to refresh
     *
     */
    doRefresh(refresher) {

        this.getEvento(refresher);
        this.getReservados();
    }

    /***************************************************************************************************************************************
     **** RECUPERAR DATOS *****************************************************************************************************************/

    /**
     * Recupera el evento por su ID
     *
     */
    async getEvento(refresher = null) {


        this.conectorApi.getEvento(this.user, this.idEvento).then(
            (result: ApiResponse) => {

                if (result.status === 1) {

                    this.evento = result.data;
                    this.ventas = this.evento.ventas;

                    if (this.bucadorInput) {
                        this.getItems(null, this.bucadorInput);
                    }

                    setTimeout(() => {

                        this.scrollToReserva(this.idReserva);
                    }, 300);
                }

                refresher ? refresher.target.complete() : refresher = null;

            }, (err) => {


                console.log('error');
                this.gl.showToast(err.mensaje, err.duracion);
                refresher ? refresher.target.complete() : refresher = null;

            });
    }

    /**
     * Recupera los reservados libres
     *
     */
    async getReservados(refresher = null) {

        this.conectorApi.getReservadosEvento(this.user, this.idEvento).then(
            (result: ApiResponse) => {

                this.reservados = result.data.mesas;
                this.reservadosAll = result.data.mesas;
                this.zonas = result.data.zonas;
                refresher ? refresher.target.complete() : refresher = null;
            }
        );
    }


    /***************************************************************************************************************************************
     **** ACTION SHEETS *******************************************************************************************************************/


    /**
     * abre las opciones de una reserva cancelada
     */
    async masOpcionesCanceladas(item: IonItemSliding, data) {

        item.close();

        const actionSheet = await this.actionSheetController.create({
            header: 'Opciones reserva',
            buttons: [
                {
                    text: 'Abrir conversación',
                    handler: () => {
                        this.abreCharReserva(null, data);
                    }
                }, {
                    text: 'Aceptar solicitud',
                    handler: () => {
                        this.estadoReserva(null, data, 1);
                    }
                }, {
                    text: 'Poner en espera',
                    handler: () => {
                        this.estadoReserva(null, data, 0);
                    }
                }, {
                    text: 'Eliminar solicitud',
                    role: 'destructive',
                    handler: () => {
                        this.deleteReserva(null, data);
                    }
                }, {
                    text: 'Cancelar',
                    role: 'cancel',
                    handler: () => {
                    }
                }]
        });
        await actionSheet.present();
    }


    /**
     * abre las opciones de una reserva Aceptada
     *
     */
    async masOpcionesAceptadas(item: IonItemSliding, data) {

        item.close();

        const actionSheet = await this.actionSheetController.create({
            header: 'Opciones reserva',
            buttons: [
                {
                    text: 'Abrir conversación',
                    handler: () => {
                        this.abreCharReserva(null, data);
                    }
                }, {
                    text: 'Cambio de mesa',
                    handler: () => {

                        this.editarMesaReserva(null, data);
                    }
                },
                {
                    text: 'Cambiar relaciones publicas',
                    handler: () => {

                        this.editarRelacionesPublicas(null, data);
                    }
                }, {
                    text: 'Ver/Editar datos',
                    handler: () => {
                        this.editarReserva(null, data);
                    }
                }, {
                    text: 'Checking',
                    handler: () => {
                        this.checkingReserva(null, data);
                    }
                }, {
                    text: 'Email de confirmación',
                    handler: () => {
                        this.emailConfirmacionReserva(null, data);
                    }
                }, {
                    text: 'Poner en espera',
                    handler: () => {
                        this.estadoReserva(null, data, 0);
                    }
                }, {
                    text: 'Cancelar reserva',
                    handler: () => {
                        this.estadoReserva(null, data, 2);
                    },

                }, {
                    text: data.importante ? 'Desmarcar como importante' : 'Marcar como importante',
                    handler: () => {
                        this.setReservaImportante(null, data);
                    }
                }, {
                    text: 'NoShow',
                    handler: () => {
                        this.estadoReserva(null, data, 5);
                    }
                }
                , {
                    text: 'Cancelar',
                    role: 'cancel',
                    handler: () => {
                    }
                }]
        });
        await actionSheet.present();
    }


    /**
     * abre las opciones de una reserva aceptada y con el checking
     */
    async masOpcionesChecking(item: IonItemSliding, data) {

        item.close();

        const actionSheet = await this.actionSheetController.create({
            header: 'Opciones reserva',
            buttons: [
                {
                    text: 'Añadir consumo',
                    handler: () => {
                        this.addConsumo(null, data);
                    }
                }, {
                    text: 'Ver consumo',
                    handler: () => {
                        this.verConsumo(null, data);
                    }
                }, {
                    text: 'Cambio de mesa',
                    handler: () => {

                        this.editarMesaReserva(null, data);
                    }
                }, {
                    text: 'Cambiar relaciones publicas',
                    handler: () => {

                        this.editarRelacionesPublicas(null, data);
                    }
                }, {
                    text: 'Cerrar mesa',
                    handler: () => {

                        this.estadoReserva(null, data, 4);
                    }
                }, {
                    text: 'Cancelar',
                    role: 'cancel',
                    handler: () => {
                    }
                }]
        });
        await actionSheet.present();
    }

    /**
     * abre las opciones de una reserva aceptada y con el checking
     */
    async masOpcionesEvento() {

        const actionSheet = await this.actionSheetController.create({
            header: 'Opciones evento',
            buttons: [
                {
                    text: 'Añadir reserva',
                    handler: () => {
                        this.abreEventosMesas();
                    }
                }, {
                    text: 'Compartir evento',
                    handler: () => {
                        this.socialShare();
                    }
                }, {
                    text: 'Abrir scanner QR',
                    handler: () => {
                        this.openQrScanner();
                    }
                }, {
                    text: 'Enviarme informe',
                    handler: () => {
                        this.emailInformeEvento(null);
                    }
                }, {
                    text: 'Enviar email a todos',
                    handler: () => {
                        this.emailConfirmacionReservaTodos(null);
                    }
                }, {
                    text: 'Cancelar',
                    role: 'cancel',
                    handler: () => {
                    }
                }]
        });
        await actionSheet.present();
    }


    /**
     * TODO: Abre las opciones de una mesa del salon
     *
     */
    async masOpcionesMesa(item) {

        const actionSheet = await this.actionSheetController.create({
            header: 'Opciones de ' + item.nombre,
            buttons: this.createOpcionesMesas(item)
        });

        await actionSheet.present();
    }

    /**
     * Deveulve los botones de las mess segun su estado (Solo lo que pueden hacer)
     */
    createOpcionesMesas(item) {

        const buttons = [];

        buttons.push({
            text: 'Ver reservas',
            handler: () => {

                this.abreReservasMesa(item);
            }
        });

        if (item.libre && !item.bloqueado && !item.bloqueado_web) {

            buttons.push({
                text: 'Añadir reserva',
                handler: () => {

                    if (item.libre) {

                        this.abreSolicitud(item);
                    } else {

                        this.gl.showToast('Esta mesa esta ocupada, hasta que no quede libre no se pueden añadir mas reservas.');
                    }
                }
            });
        }

        if (item.aceptada && !item.bloqueado && !item.bloqueado_web) {

            buttons.push({
                text: 'Forzar reserva',
                handler: () => {

                    this.confirmForzarReserva(item);
                }
            });
        }


        if (item.checking) {

            buttons.push(
                {
                    text: 'Cambiar de mesa',
                    handler: () => {

                        if (item.checking) {

                            const idReserva = item.checking.id;
                            const reserva = this.ventas.find((q) => {
                                return q.id === idReserva;
                            });

                            if (reserva) {

                                this.editarMesaReserva(null, reserva);

                            } else {

                                this.gl.showToast('No se ha podido acceder a la reserva. Actualiza el evento.');
                            }

                        } else {

                            this.gl.showToast('No hay ninguna reserva con checkin para cerrar.');
                        }
                    }
                }
            );

            buttons.push({
                text: 'Cerrar reserva',
                handler: () => {

                    if (item.checking) {

                        this.estadoReserva(null, item.checking, 4);

                    } else {

                        this.gl.showToast('No hay ninguna reserva con checkin para cerrar.');
                    }
                }
            });
        }

        if (item.libre && !item.bloqueado_web) {

            buttons.push({
                text: (item.bloqueado ? 'Desbloquear' : 'Bloquear'),
                handler: () => {

                    this.bloquearMesa(item);
                }
            });
        }

        buttons.push({
            text: 'Cancelar',
            role: 'cancel',
            handler: () => {
            }
        });

        return buttons;
    }


    /***************************************************************************************************************************************
     ****METODOS RESERVA ******************************************************************************************************************/

    /**
     * Abre el modal de edicion de una reserva
     * Con la clase Eventos
     */
    async editarReserva(item: IonItemSliding = null, data) {

        if (item) {
            item.close();
        }

        this.ev.editarReserva(data).then((result) => {

            this.getEvento();
        });
    }


    /**
     * Abre el modal de edicion de una reserva
     * Con la clase Eventos
     */
    async verConsumo(item: IonItemSliding = null, data) {

        if (item) {
            item.close();
        }

        this.ev.verConsumo(data).then((result) => {

            if (result) {

                this.getEvento();
            }
        });
    }


    /**
     * Abre el modal de edicion de una reserva
     * Con la clase Eventos
     *
     */
    async addConsumo(item: IonItemSliding, data) {

        if (item) {
            item.close();
        }

        this.ev.addConsumo(data).then((result) => {

            if (result) {

                this.getEvento();
            }
        });
    }


    /**
     * Abre el modal para la edicion de la reserva
     * Con la clase Eventos
     *
     */
    async editarMesaReserva(item: IonItemSliding, data) {

        if (item) {
            item.close();
        }

        let itemMesa = data;

        this.ev.editarMesaReserva(data, this.reservados, this.zonas).then((result) => {

            if (result) {

                this.ev.setMesaReserva(data, result.id, result.tipo).then((res) => {

                    if (res) {
                        this.getReservados();
                        // this.getEvento();
                        itemMesa.reservado = res.data.reservado;
                    }
                });
            }
        });
    }


    /**
     * Abre modal selector para cambiar relacciones publicas
     * Con la clase Eventos
     *
     */
    async editarRelacionesPublicas(item: IonItemSliding, data) {

        if (item) {
            item.close();
        }

        this.ev.editarRelacionesPublicas(data).then((result) => {

            if (result) {

                this.getEvento();
            }
        });
    }


    /**
     * Editar un dato de la reserva directamente con un alert
     * Con la clase Eventos
     *
     */
    async editarDatoReserva(item, titulo, dato, valor, forzar = false) {

        this.ev.editarDatoReserva(item, titulo, dato, valor, forzar = false).then((result) => {

            if (result) {
                // this.getEvento();
            }
        });
    }


    /**
     * cambia el estado de una reserva
     * Con la clase Eventos
     *
     */
    async estadoReserva(item: IonItemSliding = null, data, estado) {

        if (item) {
            item.close();
        }

        this.ev.estadoReserva(data, estado).then((result) => {

            if (result) {
                this.getReservados();
                this.getEvento();
            }
        });
    }

    /**
     * cambia el estado de una reserva
     * Con la clase Eventos
     *
     */
    async setReservaImportante(item: IonItemSliding = null, data) {

        if (item) {
            item.close();
        }

        this.ev.setImportante(data).then((result) => {

            if (result) {
                data.importante = !data.importante;
            }
        });
    }


    /**
     * Elimina una reserva
     * Con la clase Eventos
     *
     */
    async deleteReserva(item: IonItemSliding = null, data) {

        if (item) {
            item.close();
        }

        this.ev.deleteReserva(data).then((result) => {

            if (result) {
                const index = this.evento.ventas.indexOf(data);
                this.evento.ventas.splice(index, 1);
                this.ventas = this.evento.ventas;
                this.getEvento();
            }
        });
    }


    /**
     * Abre para confirmar el numero de gente que hay en la reseva antes de hacer el checking
     * Con la clase Eventos
     *
     */
    async checkingReserva(item: IonItemSliding = null, data) {

        if (item) {
            item.close();
        }

        this.ev.confirmCheckingReserva(data).then((result) => {

            if (result) {
                this.getEvento();
                this.getReservados();
            }
        });
    }

    /**
     * Abre el modal de chat de una resrva
     * Con la clase Eventos
     *
     */
    async abreCharReserva(item: IonItemSliding = null, data) {

        if (item) {
            item.close();
        }

        this.ev.abreChatReserva(data).then((result) => {

            if (result) {

                this.getEvento();
            }
        });
    }


    /**
     * Envia el email de confirmacion al cliente
     * Con la clase Eventos
     */
    async emailConfirmacionReserva(item: IonItemSliding = null, data) {

        if (item) {

            item.close();
        }

        this.ev.emailConfirmacionReserva(data).then((result) => {

            if (result) {
                this.getEvento();
            }
        });
    }


    /***************************************************************************************************************************************
     **** METODOS EVENTO ******************************************************************************************************************/

    /**
     * Enviar email a todos
     */
    async emailConfirmacionReservaTodos(item: IonItemSliding = null) {

        if (item) {

            item.close();
        }

        if (this.gl.checkPermissions('envio_email_todos', 'accion')) {

            const loading = await this.loadCtrl.create({
                translucent: false,
            });

            loading.present();

            this.conectorApi.sedEmailConfirmacionToAll(this.user, {id: this.evento.id}).then(
                (result: ApiResponse) => {

                    if (result.status === 1) {

                        this.gl.showToast(result.mensaje, result.duracion, 'success');
                        this.getEvento();
                    } else {

                        this.gl.showToast(result.mensaje, result.duracion);
                    }

                    loading.dismiss();
                },
                (err) => {

                    this.gl.showToast(err.mensaje);
                    loading.dismiss();
                });
        }
    }


    /**
     * Enviar con el informe de las reservas
     */
    async emailInformeEvento(item: IonItemSliding = null) {

        if (item) {

            item.close();
        }


        const loading = await this.loadCtrl.create({
            translucent: false,
        });

        loading.present();

        this.conectorApi.sendEmailInformeEvento(this.user, {id: this.evento.id}).then(
            (result: ApiResponse) => {

                if (result.status === 1) {

                    this.gl.showToast(result.mensaje, result.duracion, 'success');
                } else {

                    this.gl.showToast(result.mensaje, result.duracion);
                }

                loading.dismiss();
            },
            (err) => {

                this.gl.showToast(err.mensaje);
                loading.dismiss();
            });
    }


    /**
     * Abre el modal de social sharing
     *
     */
    async socialShare() {

        const loading = await this.loadCtrl.create({
            duration: 6000,
            translucent: false,
        });

        loading.present();

        this.socialSharing.share(this.evento.nombre, this.evento.nombre, (this.evento.img ? this.evento.img : null)).then(() => {

            loading.dismiss();

        }).catch(() => {

            this.gl.showToast('No se puede compartir este evento', 2000);
            loading.dismiss();
        });
    }


    /***************************************************************************************************************************************
     **** METODOS SALON ******************************************************************************************************************/

    /**
     * Bloquea una mesa para un evento en el caso de no estar ocupada ya
     *
     */
    async bloquearMesa(item) {

        if (this.gl.checkPermissions('bloquear_mesas', 'accion')) {

            if (item.libre) {

                const loading = await this.loadCtrl.create({
                    translucent: false,
                });

                loading.present();

                this.conectorApi.bloquearReservadoEvento(this.user, {id_reservado: item.id, id_evento: this.evento.id}).then(
                    (result: ApiResponse) => {


                        this.getReservados();
                        this.gl.showToast(result.mensaje, result.duracion, (result.status === 1 ? 'success' : 'warning'));
                        loading.dismiss();
                    },
                    (err) => {

                        this.gl.showToast(err.mensaje);
                        loading.dismiss();
                    });

            } else {

                this.gl.showToast('Esta mesa esta reservada no se puede bloquear', 2000);
            }
        }
    }


    /**
     * Abre el modal con las reservas de una mesa en concreto
     * Cuando pongamos mas opciones utilizar funcion masOpcionesMesa
     *
     */
    async abreReservasMesa(item) {

        const modal = await this.modalCtrl.create({
            component: ModalReservasMesaPage,
            componentProps: {evento: this.evento, mesa: item}
        });

        modal.onDidDismiss().then((resultado) => {

            this.getReservados();
            this.getEvento();
        });

        return await modal.present();
    }


    async confirmForzarReserva(item) {

        const alert = await this.alertController.create({
            header: 'Forzar reserva',
            message: 'Si fuerzas una reserva, las reservas que hay aceptadas actualmente en esta mesa pasarán a pendientes ¿Deseas continuar?',
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                    }
                }, {
                    text: 'Si, continuar',
                    handler: (data) => {

                        this.abreSolicitud(item, true);
                    }
                }
            ]
        });
        alert.present();

    }

    /**
     * Abre el modal para hacer una reseva
     *
     */
    async abreSolicitud(reservado = null, forzarReserva = false) {

        const modal = await this.modalCtrl.create({
            component: ModalSolicitarReservadoPage,
            componentProps: {item: this.evento, mesa: reservado, forzar: forzarReserva}
        });

        modal.onDidDismiss().then((resultado) => {

            if (resultado.data) {

                this.getEvento();
                this.getReservados();
            }
        });

        return await modal.present();
    }


    /**
     * Abre el modal de eventos mesas
     *
     */
    async abreEventosMesas() {

        const modal = await this.modalCtrl.create({
            component: ModalEventoMesasPage,
            componentProps: {evento: this.evento, mesas: this.reservados, zonas: this.zonas}
        });

        modal.onDidDismiss().then((data) => {

            if (data.data) {

                this.abreSolicitud(data.data);
            }
        });

        if (this.gl.checkPermissions('reservas', 'anadir')) {

            return await modal.present();
        }
    }

    /***************************************************************************************************************************************
     **** METODOS VISTA ********************************************************************************************************************/

    /**
     * Abre el modal de chat de una resrva
     *
     */
    async abreFiltros() {

        const modal = await this.modalCtrl.create({
            component: ModalFiltrosReservasPage,
            componentProps: {filtros: this.filtros, zonas: this.zonas}
        });

        modal.onDidDismiss().then((resultado) => {

            if (resultado.data) {
                // this.filtros = resultado.data;
            }
        });

        return await modal.present();
    }

    /**
     * Activa directamente el filtro de la zona
     *
     */
    changeFiltroZona() {

        this.filtros.num_filtros = 0;

        for (let key in this.filtros) {

            if (key !== 'num_filtros' && key !== 'eliminadas' && key !== 'cerradas' && key !== 'id_orden' && this.filtros[key] > 0) {

                this.filtros.num_filtros++;
            }
        }
    }


    /**
     * Abre el lector de QR
     *
     */
    openQrScanner() {

        this.barcodeScanner.scan().then(barcodeData => {

            if (barcodeData && barcodeData.text) {

                let encontrado = false;
                let chequeado = false;

                for (let item of this.evento.ventas) {

                    if (item.id == barcodeData.text) {

                        encontrado = true;

                        if (!item.checkin) {

                            chequeado = true;
                            this.checkingReserva(null, item);
                        }

                        break;
                    }
                }

                if (!encontrado) {

                    this.gl.showToast('La reserva no se ha encontrao actualiza, por si es una reserva nueva!', 2000);

                } else if (!chequeado) {

                    this.gl.showToast('La reserva ya ha sido checkeada.', 2000);
                }

            } else {

                this.gl.showToast('No es un codigo valido.', 2000);
            }

        }).catch(err => {

            // this.gl.showToast('No es un codigo QR valido', 2000);
        });
    }


    /**
     * Descarga y abre el PDF
     *
     */
    async downloadAndOpenPdf(url) {

        if (url) {

            this.globalLoading = await this.loadCtrl.create({
                message: 'Descargando...'
            });
            this.globalLoading.present();


            const downloadUrl = url;
            const path = this.file.dataDirectory;


            this.transfer.download(downloadUrl, path + 'myfile.pdf').then(entry => {

                this.openLocalPdf(entry.toURL());
                this.globalLoading.message = 'Abriendo...';
                this.globalLoading.dismiss();
            }, error => {

                console.log(error);
            });
        }
    }


    /**
     * Abre un fichero local en pdf con el fileOpener
     *
     */
    openLocalPdf(filePath, tittle = 'Catalogo PDF', type = 'application/pdf') {

        this.fileOpener.open(filePath, type)
            .then(() => console.log('File is opened'))
            .catch(e => console.log('Error opening file', e));
    }


    /**
     * Realiza el scroll hasta una reserva
     *
     */
    scrollToReserva(id) {

        const item = document.getElementById('res' + id);
        const contenido = document.getElementById('my-content');
        const referencia = document.getElementById('ref-scroll');

        if (item && referencia) {

            this.myContent.getScrollElement().then((res) => {

                const rect = item.getBoundingClientRect();
                const ref = referencia.getBoundingClientRect();
                const header = contenido.offsetTop;

                const posicion = (rect.top - header) - (ref.top - header);

                this.myContent.scrollToPoint(0, posicion, 300);
            });
        }
    }


    /**
     * Resetea los itmes
     *
     */
    onCancel(ev: any) {

        this.ventas = this.evento.ventas;
    }


    /**
     * Devuelve los items filtrados
     *
     */
    getItems(ev: any = null, forzar = '') {

        this.ventas = this.evento.ventas;

        const val = ev ? ev.target.value : forzar;

        if (val && val.trim() !== '') {
            this.ventas = this.ventas.filter((item) => {
                return item.nombre.toLowerCase().includes(val.toLowerCase()) || item.usuario.toLowerCase().includes(val.toLowerCase());
            });
        }
    }

    /**
     * Resetea los itmes
     *
     */
    onCancelSalon(ev: any) {

        this.reservados = this.reservadosAll;
    }


    /**
     * Devuelve los items filtrados
     *
     */
    getItemsSalon(ev: any = null, forzar = '') {

        this.reservados = this.reservadosAll;

        const val = ev ? ev.target.value : forzar;

        if (val && val.trim() !== '') {
            this.reservados = this.reservados.filter((item) => {

                return (item.aceptada && (item.aceptada.nombre.toLowerCase().includes(val.toLowerCase()) || item.aceptada.usuario.toLowerCase().includes(val.toLowerCase()))) ||
                    (item.checking && (item.checking.nombre.toLowerCase().includes(val.toLowerCase()) || item.checking.usuario.toLowerCase().includes(val.toLowerCase())));
            });
        }
    }
}
