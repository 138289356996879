import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'mesas'
})
export class MesasPipe implements PipeTransform {

    transform(items: any[], filter: any[]) {

        if (filter['id_zona']) {

            items = items.filter((item) => {
                return item.id_zona === filter['id_zona'];
            });
        }

        if (filter['libres']) {

            items = items.filter((item) => {
                return item.libre === filter['libres'];
            });
        }

        if (filter['bloqueadas']) {

            items = items.filter((item) => {
                return item.bloqueado === 0;
            });
        }

        return items;
    }
}
