import {Injectable, NgZone} from '@angular/core';
import {
    ActionSheetController,
    AlertController, IonItemSliding,
    LoadingController,
    ModalController,
    NavController,
    Platform,
    ToastController
} from '@ionic/angular';
import {UserService} from '../Services/user-service.service';
import {ApiResponse, ConectorApi, ReservaForm} from './conector-api';
import {Globales} from './globales';
import {File} from '@ionic-native/file/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {UsuariosService} from '../Services/usuarios.service';
import {ActivatedRoute} from '@angular/router';
import {PhotoViewer} from '@ionic-native/photo-viewer/ngx';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import {EventosStorageService} from '../Services/eventosV2.service';
import {ModalEditarSolicitudReservadoPage} from '../Pages/Eventos/modal-editar-solicitud-reservado/modal-editar-solicitud-reservado.page';
import {ModalConsumoPage} from '../Pages/Eventos/modal-consumo/modal-consumo.page';
import {ModalAddConsumoPage} from '../Pages/Eventos/modal-add-consumo/modal-add-consumo.page';
import {ModalCambioMesaPage} from '../Pages/Eventos/modal-cambio-mesa/modal-cambio-mesa.page';
import {ModalEditarRelacionesPublicasPage} from '../Pages/Eventos/modal-editar-relaciones-publicas/modal-editar-relaciones-publicas.page';
import {ModalChatSolicitudReservadoPage} from '../Pages/Eventos/modal-chat-solicitud-reservado/modal-chat-solicitud-reservado.page';
import {IonicEvents} from './IonicEvents';

@Injectable()

export class Eventos {


    user: any;
    form: ReservaForm;
    evento: any;
    ventas: any;
    reservados: any;

    constructor(
        public zone: NgZone,
        private navCtrl: NavController,
        private loadCtrl: LoadingController,
        private modalCtrl: ModalController,
        private actionSheetController: ActionSheetController,
        private alertController: AlertController,
        private platform: Platform,
        private conectorApi: ConectorApi,
        private gl: Globales,
        public file: File,
        public ft: FileTransfer,
        private fileOpener: FileOpener,
        private userService: UserService,
        private usuariosService: UsuariosService,
        private route: ActivatedRoute,
        private events: IonicEvents,
        private photoViewer: PhotoViewer,
        private socialSharing: SocialSharing,
        private barcodeScanner: BarcodeScanner,
        private eventosService: EventosStorageService,
    ) {

        this.user = this.userService.get();
    }


    /**
     * Abre el modal de edicion de una reserva
     *
     */
    async editarReserva(data): Promise<any> {

        const modal = await this.modalCtrl.create({
            component: ModalEditarSolicitudReservadoPage,
            componentProps: {item: data}
        });

        modal.present();

        return modal.onDidDismiss().then((resultado) => {

            return resultado.data;
        });

    }


    /**
     * Abre el modal de edicion de una reserva
     *
     */
    async verConsumo(data): Promise<any> {

        const modal = await this.modalCtrl.create({
            component: ModalConsumoPage,
            componentProps: {item: data}
        });

        if (this.gl.checkPermissions('consumo_reserva', 'ver')) {
            modal.present();
        }

        return modal.onDidDismiss().then((resultado) => {

            return resultado.data;
        });

    }


    /**
     * Abre el modal de edicion de una reserva
     *
     */
    async addConsumo(data): Promise<any> {

        const modal = await this.modalCtrl.create({
            component: ModalAddConsumoPage,
            componentProps: {item: data}
        });

        if (this.gl.checkPermissions('consumo_reserva', 'anadir')) {
            modal.present();
        }

        modal.onDidDismiss().then((resultado) => {

            return resultado.data;
        });
    }


    /**
     * Abre el modal para la edicion de la reserva
     *
     */
    async editarMesaReserva(reserva, mesasEvento, zonasSala = null): Promise<any> {

        const modal = await this.modalCtrl.create({
            component: ModalCambioMesaPage,
            componentProps: {item: reserva, mesas: mesasEvento, zonas: zonasSala}
        });

        if (this.gl.checkPermissions('reservas', 'editar')) {

            modal.present();
        }

        return modal.onDidDismiss().then((respuesta) => {

            return respuesta.data;
        });
    }

    /**
     * cambia la mesa de un reservado
     *
     */
    async setMesaReserva(data, idNuevaMesa, tipoCambio = null): Promise<any> {

        const loading = await this.loadCtrl.create({
            translucent: false,
        });

        loading.present();


        return this.conectorApi.editMesaReservado(this.user, {id: data.id, idReservado: idNuevaMesa, tipoCambioReservado: tipoCambio}).then(
            (result: ApiResponse) => {

                loading.dismiss();

                if (result.status === 1) {

                    this.gl.showToast(result.mensaje, result.duracion, 'success');

                    return result;

                } else {

                    this.gl.showToast(result.mensaje, result.duracion);
                }

                return false;
            },
            () => {

                loading.dismiss();

                return false;
            });
    }

    /**
     * Abre modal selector para cambiar relacciones publicas
     *
     */
    async editarRelacionesPublicas(data): Promise<any> {


        const modal = await this.modalCtrl.create({
            component: ModalEditarRelacionesPublicasPage,
            componentProps: {item: data}
        });

        if (this.gl.checkPermissions('reservas', 'editar')) {

            modal.present();
        }

        return modal.onDidDismiss().then((respuesta) => {

            return respuesta.data;
        });
    }


    /**
     * Editar un dato de la reserva directamente con un alert
     *
     */
    async editarDatoReserva(item, titulo, dato, valor, forzar = false): Promise<any> {

        this.form = {
            id: item.id,
            nombre: item.nombre,
            email: item.email,
            pax: item.pax,
            precio: item.precio,
        };

        const alert = await this.alertController.create({
            header: titulo,
            inputs: [
                {
                    name: 'dato',
                    type: 'number',
                    value: valor,
                },
            ],
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                    }
                }, {
                    text: 'Guardar',
                    handler: (data) => {

                        if (this.form[dato] !== data.dato) {

                            this.form[dato] = data.dato;
                            item[dato] = data.dato;

                            this.setSolicidud().then((res) => {
                                return res;
                            });
                        }
                    }
                }
            ]
        });

        if (this.gl.checkPermissions('reservas', 'editar')) {

            if (!item.checkin || forzar) {

                alert.present();

                return alert.onDidDismiss().then((res) => {

                    return res;
                });

            } else if (dato === 'precio') {

                return this.verConsumo(item).then((res) => {
                    return false;
                });

            } else if (dato === 'pax') {

                return this.editarDatoReserva(item, 'Checkin', 'checkin', item.checkin, true).then((res) => {
                    return res;
                });

            } else {

                this.gl.showToast('Este dato no se puede cabiar una vez realizado el checking', 3000);
                return false;
            }
        }

        return false;
    }

    /**
     * Guarda los datos de una solicitud
     *
     */
    async setSolicidud(): Promise<any> {


        const loading = await this.loadCtrl.create({
            translucent: false,
        });

        loading.present();


        return this.conectorApi.editSolicitudReservado(this.user, this.form).then(
            (result: ApiResponse) => {

                if (result.status === 1) {
                    this.gl.showToast(result.mensaje, result.duracion, 'success');

                } else if (result.status === -1) {

                    this.events.publishSomeData({
                        name: 'user:logout'
                    });
                    this.gl.showToast(result.mensaje, result.duracion);

                } else {

                    this.gl.showToast(result.mensaje, result.duracion);
                }
                // this.getEvento();
                loading.dismiss();
                return result;
            },
            () => {

                loading.dismiss();
                return false;
            });

    }

    /**
     * cancela una reserva
     */
    async estadoReserva(data, estado): Promise<any> {


        if (this.gl.checkPermissions('reservas', 'editar')) {


            const loading = await this.loadCtrl.create({
                translucent: false,
            });
            loading.present();

            return this.conectorApi.cambioEstadoSolicitud(this.user, {id: data.id, aceptado: estado}).then(
                (result: ApiResponse) => {

                    if (result.status === 1) {

                        data = result.data;
                        this.gl.showToast(result.mensaje, result.duracion, 'success');

                    } else if (result.status === -1) {

                        this.events.publishSomeData({
                            name: 'user:logout'
                        });
                        this.gl.showToast(result.mensaje, result.duracion);

                    } else {

                        this.gl.showToast(result.mensaje, result.duracion);
                    }

                    loading.dismiss();
                    return true;

                }, (err) => {
                    loading.dismiss();
                    this.gl.showToast(err.mensaje, err.duracion);
                    return false;
                });

        } else {
            return false;
        }
    }

    /**
     * cancela una reserva
     */
    async deleteReserva(data): Promise<any> {


        if (this.gl.checkPermissions('reservas', 'borrar')) {

            const loading = await this.loadCtrl.create({
                translucent: false,
            });

            loading.present();

            return this.conectorApi.deleteSolicitud(this.user, {id: data.id}).then(
                (result: ApiResponse) => {

                    loading.dismiss();

                    if (result.status === 1) {

                        return true;
                    } else {
                        return false;
                    }


                }, (err) => {
                    loading.dismiss();
                    this.gl.showToast(err.mensaje, err.duracion);
                    return false;
                });

        } else {

            return false;
        }
    }


    /**
     * cancela una reserva
     */
    async setImportante(data): Promise<any> {


        if (this.gl.checkPermissions('reservas', 'editar')) {

            const loading = await this.loadCtrl.create({
                translucent: false,
            });

            loading.present();

            return this.conectorApi.setReservadoImportante(this.user, {id: data.id}).then(
                (result: ApiResponse) => {

                    loading.dismiss();

                    if (result.status === 1) {

                        return true;

                    } else {

                        return false;
                    }


                }, (err) => {
                    loading.dismiss();
                    this.gl.showToast(err.mensaje, err.duracion);
                    return false;
                });

        } else {

            return false;
        }
    }


    /**
     * Abre para confirmar el numero de gente que hay en la reseva antes de hacer el checking
     *
     */
    async confirmCheckingReserva(data): Promise<any> {


        const mensaje = '<div>' + data.nombre + ' + ' + data.pax + '</div><div>' + data.zona + ': ' + data.reservado.nombre + '</div><div>'
            + data.pago + ':' + data.precio + '€</div><div>RRPP: ' + data.usuario + '</div>';
        const alert = await this.alertController.create({
            header: 'Checking',
            message: mensaje,
            subHeader: 'Introduce el numero de personas que finalmente entran',
            inputs: [
                {
                    name: 'dato',
                    type: 'number',
                    value: data.pax,
                },
            ],
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                    }
                }, {
                    text: 'Guardar',
                    handler: (respuesta) => {

                        if (respuesta.dato) {

                            return this.checkReserva(data, respuesta.dato).then((res) => {

                                return res;
                            });
                        }
                    }
                }
            ]
        });

        if (this.gl.checkPermissions('checking_reserva', 'accion')) {

            alert.present();

        } else {

            return false;
        }

        return alert.onDidDismiss().then((res) => {

            return res;
        });
    }


    /**
     * Realiza el checking de  una reserva
     */
    async checkReserva(data, numPax): Promise<any> {


        const loading = await this.loadCtrl.create({
            translucent: false,
        });

        loading.present();


        return this.conectorApi.checkReserva(this.user, {id: data.id, pax: numPax}).then(
            (result: ApiResponse) => {

                loading.dismiss();

                if (result.status === 1) {
                    this.gl.showToast(result.mensaje, result.duracion, 'success');
                    return true;

                } else if (result.status === -1) {

                    this.events.publishSomeData({
                        name: 'user:logout'
                    });
                    this.gl.showToast(result.mensaje, result.duracion);
                    return false;

                } else {

                    this.gl.showToast(result.mensaje, result.duracion);
                    return false;
                }
            },
            (err) => {

                this.gl.showToast(err.mensaje);
                loading.dismiss();
                return false;
            });
    }

    /**
     * Envia el email de confirmacion al cliente
     */
    async emailConfirmacionReserva(data): Promise<any> {


        if (this.gl.checkPermissions('envio_email_confirmacion', 'accion')) {
            const loading = await this.loadCtrl.create({
                translucent: false,
            });

            loading.present();

            return this.conectorApi.sendEmailConfirmacion(this.user, {id: data.id}).then(
                (result: ApiResponse) => {

                    loading.dismiss();

                    if (result.status === 1) {

                        this.gl.showToast(result.mensaje, result.duracion, 'success');
                        return true;
                    } else {

                        this.gl.showToast(result.mensaje, result.duracion);
                        return false;
                    }

                },
                (err) => {

                    this.gl.showToast(err.mensaje);
                    loading.dismiss();
                    return false;
                });
        } else {

            return false;
        }
    }

    /**
     * Abre el modal de chat de una resrva
     *
     */
    async abreChatReserva(data): Promise<any> {


        const modal = await this.modalCtrl.create({
            component: ModalChatSolicitudReservadoPage,
            componentProps: {item: data}
        });

        modal.present();

        return modal.onDidDismiss().then((resultado) => {
            return resultado.data;
        });

    }

    /**
     * Abre mas información sobre la resevra
     *
     */
    async showAceptInfo(item) {

        if ((item.aceptado === 1 || item.aceptado === 2) && item.aceptado_por) {

            let msg = '<p>' + (item.aceptado === 1 ? 'Aceptada' : 'Cancelada') + ' por <strong>' + item.aceptado_por + '</strong></p>';

            if (item.atendido_por) {

                msg += '<p>Cheking por <strong>' + item.atendido_por + '</strong></p>';
            }

            const alert = await this.alertController.create({
                header: 'Información estado',
                message: msg,
            });

            await alert.present();

        } else {

            this.gl.showToast('No hay información diponible sobre el estado.', 2000);
        }
    }


    /**
     * Abre la imagen de la reserva
     *
     */
    abreImagen(item) {
        if (item.archivo) {
            const options = {share: true, closeButton: false};
            this.photoViewer.show(item.archivo, 'Archivo reserva');

        } else {
            this.gl.showToast('Imagen no disponible', 2000);
        }
    }
}
