import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';

import {PipesModule} from '../Pipes/pipes.module';

import {ReservaListItemComponent} from './Reservas/reserva-list-item/reserva-list-item.component';
import {OcupacionZonasComponent} from './Eventos/ocupacion-zonas/ocupacion-zonas.component';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, PipesModule],
    declarations: [ReservaListItemComponent, OcupacionZonasComponent],
    exports: [ReservaListItemComponent, OcupacionZonasComponent]
})
export class ComponentsModule {
}
