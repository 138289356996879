import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';
import {EventoPage} from './evento.page';

import {PipesModule} from '../../../Pipes/pipes.module';
import {ComponentsModule} from '../../../Components/components.module';

const routes: Routes = [
    {
        path: '',
        component: EventoPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PipesModule,
        ComponentsModule,
        RouterModule.forChild(routes)
    ],
    exports: [],
    declarations: [EventoPage]
})
export class EventoPageModule {
}
