import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ReservasService {

    tabla = 'smd_vp_reservas';
    data;

    constructor() {

        this.data = JSON.parse(localStorage.getItem(this.tabla)) || [];

    }


    get() {

        return this.data;

    }


    getById(id) {

        let aux = null;

        for (const item of this.data) {

            if (item.id === id) {
                aux = item;
                break;
            }
        }

        return aux;
    }

    set(data) {

        this.data = data;
        localStorage.setItem(this.tabla, JSON.stringify(data));

        return this.data;

    }


    delete() {

        this.data = null;
        localStorage.setItem(this.tabla, JSON.stringify([]));

        return this.data;
    }


    destroy() {

        localStorage.clear();

    }
}
