import {Component, OnInit} from '@angular/core';
import {LoadingController, ModalController, NavController, NavParams, Platform} from '@ionic/angular';
import {ApiResponse, ConectorApi, ReservaForm} from '../../../Classes/conector-api';
import {Globales} from '../../../Classes/globales';
import {UserService} from '../../../Services/user-service.service';
import {UsuariosService} from '../../../Services/usuarios.service';


@Component({
    selector: 'app-modal-editar-relaciones-publicas',
    templateUrl: './modal-editar-relaciones-publicas.page.html',
    styleUrls: ['./modal-editar-relaciones-publicas.page.scss'],
})
export class ModalEditarRelacionesPublicasPage implements OnInit {

    user: any;
    return: any;
    usuarios: any;
    data: any;
    bucadorInput: any;
    form: ReservaForm;

    constructor(
        private navParams: NavParams,
        private navCtrl: NavController,
        private loadCtrl: LoadingController,
        private modalCtrl: ModalController,
        public platform: Platform,
        private conectorApi: ConectorApi,
        private gl: Globales,
        private userService: UserService,
        private usuariosService: UsuariosService,
    ) {

        this.user = this.userService.get();
        this.data = this.navParams.get('item');
        this.return = this.navParams.get('return');

        this.form = {
            id: this.return ? null : this.data.id,
            idUsuario: this.return ? this.data.id : this.data.id_usuario,
        };
    }

    ngOnInit() {

    }


    ionViewDidEnter() {

        this.usuarios = this.usuariosService.get();
    }

    /**
     * Funcion que se ejecuta cuando se hace la accion pull to refresh
     *
     */
    doRefresh(refresher) {

        this.getUsuarios(refresher);
    }


    /**
     * Recupera el listado de raking
     *
     */
    async getUsuarios(refresher = null, loader = false) {

        let loading = null;

        if (loader) {

            loading = await this.loadCtrl.create({
                translucent: false,
            });

            loading.present();
        }

        this.conectorApi.getUsuarios(this.user).then(
            (result: ApiResponse) => {

                if (result.status === 1) {

                    this.bucadorInput = null;
                    this.usuarios = this.usuariosService.set(result.data);

                } else {
                    this.gl.showToast(result.mensaje, result.duracion);
                }

                refresher ? refresher.target.complete() : refresher = null;
                loading ? loading.dismiss() : loading = null;

            }, (err) => {


                console.log('error');
                this.gl.showToast(err.mensaje, err.duracion);
                refresher ? refresher.target.complete() : refresher = null;
                loading ? loading.dismiss() : loading = null;

            });
    }


    /**
     * Guarda las modificaciones de la reserva
     *
     */
    async setReserva() {

        if (this.return) {

            const idUsuario = this.form.idUsuario;
            const user = this.usuarios.find((item) => {
                return item.id === idUsuario;
            });

            this.dismiss(user);

        } else {

            const loading = await this.loadCtrl.create({
                translucent: false,
            });

            loading.present();

            if (this.form.id && this.form.idUsuario) {


                this.conectorApi.editSolicitudReservado(this.user, this.form).then(
                    (result: ApiResponse) => {

                        if (result.status === 1) {
                            this.dismiss(result.data);
                            this.gl.showToast(result.mensaje, result.duracion, 'success');
                        } else {
                            this.gl.showToast(result.mensaje, result.duracion);
                        }
                        loading.dismiss();
                    },
                    () => {

                        loading.dismiss();
                    });
            } else {

                loading.dismiss();
                this.gl.showToast('Introduce todos los datos obligatorios', 2000);
            }
        }
    }


    /**
     * Resetea los itmes
     *
     */
    onCancel(ev: any) {

        this.usuarios = this.usuariosService.get();
    }


    /**
     * Devuelve los items filtrados
     *
     */
    getItems(ev: any) {

        this.usuarios = this.usuariosService.get();

        let val = ev.target.value;

        if (val && val.trim() !== '') {
            this.usuarios = this.usuarios.filter((item) => {
                return item.name.toLowerCase().includes(val.toLowerCase());
            });
        }
    }


    /**
     * Cierra el modal y devuelve a la vista el resultado
     *
     */
    async dismiss(result = null) {

        await this.modalCtrl.dismiss(result);
    }
}
