import {Component, OnInit} from '@angular/core';
import {LoadingController, ModalController, NavController, NavParams, Platform} from '@ionic/angular';
import {Globales} from '../../../Classes/globales';
import {UserService} from '../../../Services/user-service.service';

@Component({
    selector: 'app-modal-filtros-reservas',
    templateUrl: './modal-filtros-reservas.page.html',
    styleUrls: ['./modal-filtros-reservas.page.scss'],
})
export class ModalFiltrosReservasPage implements OnInit {

    user: any;
    filtros: any;
    zonas: any;

    constructor(
        private navParams: NavParams,
        private navCtrl: NavController,
        private loadCtrl: LoadingController,
        private modalCtrl: ModalController,
        public platform: Platform,
        private gl: Globales,
        private userService: UserService,
    ) {

        // this.user = this.userService.get();
        this.filtros = this.navParams.get('filtros');
        this.zonas = this.navParams.get('zonas');

        console.log(this.filtros);
        console.log(this.zonas);
    }

    ngOnInit() {
    }


    /**
     *
     *
     */
    changeEstado() {

        this.filtros.checking = 0;
    }

    /**
     *
     */
    reset() {

        for (let key in this.filtros) {

            if (key !== 'num_filtros' && key !== 'eliminadas' && key !== 'cerradas' && key !== 'id_orden') {

                this.filtros[key] = '0';
            }
        }

        this.dismiss();
    }

    /**
     * Cierra el modal y devuelve a la vista el resultado
     *
     */
    async dismiss() {

        this.filtros.num_filtros = 0;

        for (let key in this.filtros) {

            if (key !== 'num_filtros' && key !== 'eliminadas' && key !== 'cerradas' && key !== 'id_orden' && this.filtros[key] > 0) {

                this.filtros.num_filtros++;
            }
        }

        await this.modalCtrl.dismiss(this.filtros);
    }
}
