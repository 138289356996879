import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NotificacionesService {

    tabla = 'smd_vp_notificaciones';
    data;

    constructor() {

        this.data = JSON.parse(localStorage.getItem(this.tabla)) || [];

    }


    get() {

        return this.data;

    }


    set(data) {

        this.data = data;
        localStorage.setItem(this.tabla, JSON.stringify(data));

        return this.data;

    }

    getNotRead() {

        let aux = 0;

        for (let item of this.data) {
            
            if (!item.vista) {
                aux++;
            }
        }
        return aux;
    }


    delete() {

        this.data = null;
        localStorage.setItem(this.tabla, JSON.stringify([]));

        return this.data;
    }


    destroy() {

        localStorage.clear();

    }
}
