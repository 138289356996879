import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';


@Injectable({
    providedIn: 'root'
})

export class IonicEvents {

    private fooSubject = new Subject<any>();

    publishSomeData(data: any) {
        this.fooSubject.next(data);
    }

    getObservable(): Subject<any> {
        return this.fooSubject;
    }
}
