import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';
import {UserService} from '../Services/user-service.service';

@Injectable()

export class Globales {


    user: any;

    constructor(public toastController: ToastController, private userService: UserService) {

        this.user = this.userService.get();
    }

    async showToast(mensaje, duracion = 2000, colorToast = 'warning') {
        const toast = await this.toastController.create({
            message: mensaje,
            duration: duracion,
            position: 'top',
            color: colorToast,
            buttons: [
                {
                    text: '',
                    icon: 'close',
                    role: 'cancel',
                    handler: () => {
                    }
                }
            ]
        });

        if (duracion > 0) {

            toast.present();
        }
    }


    /**
     * Comprueba los permisos de un usuario
     *
     */
    checkPermissions(privilegio, permiso, plataforma = 'vip') {

        this.user = this.userService.get();

        console.log(this.user.permissions[plataforma]);
        console.log(this.user.permissions[plataforma][privilegio]);
        console.log(this.user.permissions[plataforma][privilegio][permiso]);

        if ('permissions' in this.user
            && plataforma in this.user.permissions
            && privilegio in this.user.permissions[plataforma]
            && permiso in this.user.permissions[plataforma][privilegio]
            && this.user.permissions[plataforma][privilegio][permiso]
        ) {

            return true;

        } else {

            this.showToast('No tienes permisos para realizar esta acción');
            return false;
        }

    }
}
