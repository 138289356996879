import {Component} from '@angular/core';

import {Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {

            setTimeout(() => {

                // this.statusBar.styleDefault();
                // this.statusBar.overlaysWebView(false);
                // this.statusBar.backgroundColorByName('white');
                // this.statusBar.styleDefault();
                // this.statusBar.styleBlackOpaque();
                this.splashScreen.hide();
            }, 2600);
        });
    }
}
