import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'reservas'
})
export class ReservasPipe implements PipeTransform {

    transform(items: any[], filter: any[]) {

        if (items) {
            if (!filter['eliminadas']) {

                items = items.filter(function(item) {
                    return item.aceptado != 3;
                });
            }


            if (!filter['cerradas']) {

                items = items.filter(function(item) {
                    return item.aceptado != 4;
                });
            }

            if (!filter['noshow']) {

                items = items.filter(function(item) {
                    return item.aceptado != 5;
                });
            }


            if (filter['id_estado'] && filter['id_estado'] > 0) {

                items = items.filter(function(item) {
                    return item.aceptado == filter['id_estado'] - 1;
                });
            }

            if (filter['id_zona'] && filter['id_zona'] > 0) {

                items = items.filter(function(item) {
                    return item.id_zona == filter['id_zona'];
                });
            }

            if (filter['id_zonas'] && filter['id_zonas'].length > 0) {

                items = items.filter(function(item) {
                    return filter['id_zonas'].includes(item.id_zona);
                });
            }

            if (filter['id_reservado']) {

                items = items.filter(function(item) {
                    return item.id_reservado == filter['id_reservado'];
                });
            }


            if (filter['id_orden']) {

                if (filter['id_orden'] == 'a') {

                    items.sort((a, b) => b['fecha'] - a['fecha']);

                } else if (filter['id_orden'] == 'b') {

                    items.sort((a, b) => a['fecha'] - b['fecha']);

                } else if (filter['id_orden'] == 'c') {

                    items.sort((a, b) => b['precio'] - a['precio']);

                } else if (filter['id_orden'] == 'd') {

                    items.sort((a, b) => a['precio'] - b['precio']);

                } else if (filter['id_orden'] == 'e') {

                    items.sort((a, b) => b['pax'] - a['pax']);

                } else if (filter['id_orden'] == 'f') {

                    items.sort((a, b) => a['pax'] - b['pax']);

                } else if (filter['id_orden'] == 'g') {

                    items.sort((a, b) => b['reservado']['orden'] - a['reservado']['orden']);

                } else if (filter['id_orden'] == 'h') {

                    items.sort((a, b) => a['reservado']['orden'] - b['reservado']['orden']);

                } else if (filter['id_orden'] == 'i') {

                    items.sort((a, b) => b['nombre'].localeCompare(a['nombre']));

                } else if (filter['id_orden'] == 'j') {

                    items.sort((a, b) => a['nombre'].localeCompare(b['nombre']));

                } else if (filter['id_orden'] == 'k') {

                    items.sort((a, b) => b['usuario'].localeCompare(a['usuario']));

                } else if (filter['id_orden'] == 'l') {

                    items.sort((a, b) => a['usuario'].localeCompare(b['usuario']));
                }
            }

            return items;
        }
    }
}
